import { VisitFormRouteParams, VisitFormValues } from '../types';

import { FormLoader } from '../shared/visit-form.styles';
import { Formik } from 'formik';
import React from 'react';
import { StyledForm } from './visit-form-root.styles';
import { Visit } from 'store/visits/types';
import { VisitFormModal } from 'components/visit-form/visit-form-root/visit-form-modal';
import { VisitFormRouteSteps } from './visit-form-route-steps';
import { createVisitLoadingState } from 'store/visits/selectors';
import { uiMetadataLoadingState } from 'store/ui-metadata/selectors';
import { useInitialValues } from 'components/visit-form/visit-form-root/hooks/use-initial-values.hook';
import { useIsEditMode } from 'components/visit-form/context/is-editing.hook';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { useSubmitHandler } from 'components/visit-form/visit-form-root/hooks/visit-form-root.hooks';
import { useValidationSchema } from '../visit-steps-config/validations-config.hooks';

interface VisitFormRootProps {
  visit?: Visit | null;
  visitLoading?: boolean;
}

export const VisitFormRoot: React.FC<VisitFormRootProps> = ({
  visit,
  visitLoading,
}: VisitFormRootProps): JSX.Element => {
  const { step } = useParams<VisitFormRouteParams>();
  const validationSchema = useValidationSchema(step);
  const initialValues = useInitialValues(visit);
  const isEditingMode = useIsEditMode();
  const handleSubmit = useSubmitHandler(isEditingMode);
  const uiMetadataLoading = useSelector(uiMetadataLoadingState);
  const visitsLoading = useSelector(createVisitLoadingState);
  const loading = visitsLoading || uiMetadataLoading || visitLoading;
  return (
    <Formik<VisitFormValues>
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      validateOnChange
      enableReinitialize
    >
      <VisitFormModal>
        <StyledForm>
          <VisitFormRouteSteps />
        </StyledForm>
        {loading && <FormLoader />}
      </VisitFormModal>
    </Formik>
  );
};
