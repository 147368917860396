import * as userActions from './actions';

import { AjaxError } from 'rxjs/ajax';
import { catchError, filter, map, mapTo, switchMap, withLatestFrom } from 'rxjs/operators';

import { Epic } from 'redux-observable';
import { RootAction } from 'store/actions';
import { RootDependencies } from 'store/dependencies';
import { RootState } from 'store/reducer';
import { UnauthorizedError } from 'store/user/errors';
import { isActionOf } from 'typesafe-actions';
import { of } from 'rxjs';
import qs from 'qs';
import { replace } from 'connected-react-router';
import { ERR_DEFAULT } from 'shared/consts';
import { getErrorCode } from 'store/utils';

const UNAUTHORIZED_ERROR_CODE = 401;
const NOT_FOUND_ERROR_CODE = 404;
const BAD_REQUEST_ERROR_CODE = 400;
const INTERNAL_SERVER_ERROR_CODE = 500;

const isUnauthorizedError = (error: unknown): boolean =>
  error instanceof AjaxError && error.status >= BAD_REQUEST_ERROR_CODE && error.status < INTERNAL_SERVER_ERROR_CODE;

export const externalLogin: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(userActions.externalLogin.request)),
    withLatestFrom(state$),
    switchMap(([{ payload }, state]) => {
      const { authToken, apiUrl } = payload;

      return apiClient(state, apiUrl, authToken)
        .getCurrentUser()
        .pipe(
          map(({ response }) => {
            const user = response;

            return userActions.externalLogin.success({ user, apiUrl, token: authToken as string });
          }),
          catchError((error: Error) => {
            if (isUnauthorizedError(error)) {
              return of(userActions.externalLogin.failure({ error: new UnauthorizedError(), errorCode: ERR_DEFAULT }));
            }

            return of(userActions.externalLogin.failure({ error, errorCode: getErrorCode(error) }));
          }),
        );
    }),
  );

export const externalLoginRedirect: Epic<RootAction, RootAction, RootState> = (action$, state$) =>
  action$.pipe(
    filter(isActionOf(userActions.externalLogin.success)),
    withLatestFrom(state$),
    map(([, state]) => {
      const search: string = state.router?.location?.search ?? '';
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { authToken, apiUrl, ...restSearch } = qs.parse(search.replace(/^\?/, ''));
      const newSearchString = qs.stringify(restSearch);
      const path = state.router?.location?.pathname ?? '/';

      return replace(newSearchString ? `${path}?${newSearchString}` : path);
    }),
  );

export const externalLoginFailureRedirect: Epic<RootAction, RootAction, RootState> = (action$) =>
  action$.pipe(filter(isActionOf(userActions.externalLogin.failure)), mapTo(replace('/')));

export const getCurrentUser: Epic<RootAction, RootAction, RootState, RootDependencies> = (
  action$,
  state$,
  { apiClient },
) =>
  action$.pipe(
    filter(isActionOf(userActions.getCurrentUser.request)),
    withLatestFrom(state$),
    switchMap(([, state]) =>
      apiClient(state)
        .getCurrentUser()
        .pipe(
          map(({ response: user }) => {
            return userActions.getCurrentUser.success({
              user,
            });
          }),
          catchError((error: Error) => {
            if (
              error instanceof AjaxError &&
              (error.status === UNAUTHORIZED_ERROR_CODE || error.status === NOT_FOUND_ERROR_CODE)
            ) {
              return of(userActions.getCurrentUser.failure({ error: new UnauthorizedError(), errorCode: ERR_DEFAULT }));
            }

            return of(userActions.getCurrentUser.failure({ error, errorCode: getErrorCode(error) }));
          }),
        ),
    ),
  );
