import { VisitFormRouteParams, Step, VisitFormValues, HostType, SpecialVisitType } from 'components/visit-form/types';
import { getCustomFieldsStep, getNextStep, isEscortAvailable, isFlowStepAvailable } from './visit-steps-config';

import { useFieldMetadata } from 'store/ui-metadata/hooks';
import { useBuildingData } from 'store/building/hooks';
import { useFormikContext } from 'formik';
import { useParams } from 'react-router';
import { FloorData } from '../../../store/building/types';

const getFlowStep = (isVipEnabled: boolean | null, isEscortEnabled: boolean | null): string[] => {
  if (isFlowStepAvailable(isVipEnabled, isEscortEnabled)) {
    return [Step.Flow];
  }

  return [];
};

const getHostStep = (
  hostsEnabled: boolean | null,
  availableFloors: FloorData[] | null,
  values: VisitFormValues,
): string[] => {
  if (hostsEnabled) {
    return [Step.Host, `${Step.Host}/${values.hostType ?? HostType.SELF}`];
  }

  return availableFloors?.length ? [`${Step.Host}/${HostType.SELF}`] : [];
};

const getEscortSteps = (isEscortEnabled: boolean | null, values: VisitFormValues): string[] => {
  const availableScreens = [];

  if (isEscortEnabled && isEscortAvailable(values)) {
    availableScreens.push(Step.Escort);

    if (values.specialVisitType === SpecialVisitType.ESCORT) {
      availableScreens.push(`${Step.Escort}/${SpecialVisitType.ESCORT}`);
    }
  }

  return availableScreens;
};

export const useAvailableScreens = (): string[] => {
  const { values } = useFormikContext<VisitFormValues>();
  const vipEnabled = useFieldMetadata('vip');
  const escortEnabled = useFieldMetadata('escort');
  const hostsEnabled = useFieldMetadata('alt_hosts_enabled');
  const { floors: availableFloors } = useBuildingData();
  const isCustomFieldsStepAvailable = !!useFieldMetadata('custom_fields');

  return [
    ...getFlowStep(vipEnabled, escortEnabled),
    Step.Visitor,
    ...getEscortSteps(escortEnabled, values),
    ...getHostStep(hostsEnabled, availableFloors, values),
    Step.Date,
    ...getCustomFieldsStep({ isCustomFieldsStepAvailable }),
  ];
};

export const useCurrentScreen = (): string => {
  const params = useParams<VisitFormRouteParams & { specialVisitType?: SpecialVisitType; hostType?: HostType }>();

  const { step, hostType, specialVisitType } = params;
  const stepTypes: Record<string, string | undefined> = {
    [`${Step.Host}`]: hostType,
    [`${Step.Escort}`]: specialVisitType,
  };

  return stepTypes[step] ? `${step}/${stepTypes[step]}` : step;
};

type NextStepFactory = (values: VisitFormValues) => Step | null;

export const useNextStepFactory = (): NextStepFactory => {
  const { step: currentStep } = useParams<VisitFormRouteParams>();
  const isVipEnabled = useFieldMetadata('vip');
  const isEscortEnabled = useFieldMetadata('escort');
  const isHostsEnabled = useFieldMetadata('alt_hosts_enabled');
  const { floors: availableFloors } = useBuildingData();
  const isCustomFieldsStepAvailable = !!useFieldMetadata('custom_fields');

  return (values: VisitFormValues): Step | null =>
    getNextStep({
      values,
      currentStep,
      isVipEnabled,
      isEscortEnabled,
      isHostsEnabled,
      availableFloors,
      isCustomFieldsStepAvailable,
    });
};

export const usePreviousScreen = (): string | null => {
  const availableScreens = useAvailableScreens();

  const currentScreen = useCurrentScreen();
  const currentScreenIndex = availableScreens.indexOf(currentScreen);

  return availableScreens[currentScreenIndex - 1] ?? null;
};
