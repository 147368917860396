import styled, { css } from 'styled-components';

import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';

export const FieldWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;

  margin-bottom: 20px;
  max-width: 428px;

  ${mediaMaxWidth(css`
    max-width: 100%;
  `)}
`;

export const ErrorMessage = styled.span`
  font-style: italic;
  font-weight: normal;
  font-size: 12px;
  color: ${({ theme }) => theme.colors.admin.pink};
  margin-top: 8px;
`;
