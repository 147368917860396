import { Redirect, useRouteMatch } from 'react-router';

import { HostType } from 'components/visit-form/types';
import { useFieldMetadata } from 'store/ui-metadata/hooks';
import { VisitFormHeader } from 'components/visit-form/shared/step-wrapper/visit-form-header';
import { ChooseScreenForm } from 'components/visit-form/shared/choose-screen/choose-screen-form';

export const visitorTypeForm = [
  { value: HostType.SELF, label: 'labels.selectHost.types.self' },
  { value: HostType.ALT, label: 'labels.selectHost.types.alt' },
];

export const SelectHostTypeStep = (): JSX.Element => {
  const { url } = useRouteMatch();
  const isHostsEnabled = useFieldMetadata('alt_hosts_enabled');

  return isHostsEnabled ? (
    <>
      <VisitFormHeader />
      <ChooseScreenForm
        fieldName="hostType"
        titleTranslationId="labels.selectHost.title"
        buttonTranslationId="buttons.continue"
        dataCy="select-host-type_step"
        options={visitorTypeForm}
        hideContinueUntilSelected
      />
    </>
  ) : (
    <Redirect to={`${url}/${HostType.SELF}`} />
  );
};
