import styled from 'styled-components';
import { Button } from '@hqo/react-components-library/dist/atoms/button';

export const AddButtonStyles = styled(Button)`
  height: auto;
  padding-left: 0;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.primary};

  & * {
    color: inherit;
  }

  &:disabled {
    opacity: 1;
    color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};

    & * {
      color: inherit;
    }
  }

  svg {
    margin-right: 4px;
    width: 12px;
  }
`;
