import {
  GuestItemsWrapper,
  GuestPanelTitle,
} from 'components/visit-modal/visit-details/guest-items/guest-items.styles';
import { Visitor } from 'store/visits/types';

import { EditingLink } from 'components/visit-modal/visit-details/editing-link';
import { GuestItem } from 'components/visit-modal/visit-details/guest-items/guest-item';
import { SpecialVisitType, Step } from 'components/visit-form/types';
import { useIntl } from 'react-intl';

interface GuestItemsProps {
  visitors: Visitor[];
  specialVisitType: string | undefined | null;
}
export const GuestItems = ({ visitors, specialVisitType }: GuestItemsProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const type = formatMessage({
    id: specialVisitType === SpecialVisitType.VIP ? 'labels.visitor.types.vip' : 'labels.visitor.types.guest',
  });
  const guestPanelTitle = formatMessage({ id: 'labels.visitorNames' }, { type });

  return (
    <GuestItemsWrapper>
      <GuestPanelTitle>
        <span>{guestPanelTitle}</span>
        <EditingLink step={Step.Visitor} />
      </GuestPanelTitle>
      {visitors.map((visitor, index) => (
        <GuestItem key={visitor.last_name} visitor={visitor} dataCy={`${visitor.last_name.toLowerCase()}-${index}`} />
      ))}
    </GuestItemsWrapper>
  );
};
