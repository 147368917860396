/* eslint-disable max-lines-per-function */
import { generatePickerItem } from 'components/visit-form/shared/picker';
import { RecurringSelect } from 'components/visit-form/steps/date-step/recurring-data/recurring-select';
import { RecurrenceEndsOn } from 'components/visit-form/steps/date-step/recurring-data/recurrence-ends-on';
import { RecurringContentWrapper } from 'components/visit-form/steps/date-step/recurring-data/recurring-data.styles';
import { VisitTimeSelect } from 'components/visit-form/steps/date-step/visit-time/visit-time-select';

import { FrequencyEnum, RecurrenceEnum } from 'components/visit-form/types';
import { useFieldTranslations } from 'hooks/use-field-traslations.hooks';
import { useCallback, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { VisitTimeItem } from '../visit-times/visit-times.hooks';
import { useField } from 'formik';

const getFrequencyItems = (intl: IntlShape) =>
  [
    FrequencyEnum.DAILY,
    FrequencyEnum.WEEKLY,
    FrequencyEnum.MONTHLY,
    FrequencyEnum.QUARTERLY,
    FrequencyEnum.ANNUALLY,
  ].map(generatePickerItem(intl, 'labels.frequency_values'));

const getRecurringItems = (intl: IntlShape): VisitTimeItem[] =>
  [RecurrenceEnum.NEVER, RecurrenceEnum.OCCURENCES_COUNT, RecurrenceEnum.DATE].map(
    generatePickerItem(intl, 'labels.recurrence_ends_values'),
  );

interface Props {
  frequencyValue: FrequencyEnum | null;
}

export const RecurringContent = ({ frequencyValue }: Props) => {
  const intl = useIntl();
  const { label, placeholder } = useFieldTranslations('recurrence');
  const [recurrence, setRecurrence] = useState<RecurrenceEnum | null>(null);
  const [, , { setValue: setCount }] = useField<number | null>('date.count');
  const [, , { setValue: setEndDate }] = useField<Date | null>('date.endDate');
  const handleChange = useCallback(
    (value: RecurrenceEnum) => {
      setRecurrence(value);
      if (recurrence !== RecurrenceEnum.OCCURENCES_COUNT) {
        setCount(null);
      }

      if (recurrence !== RecurrenceEnum.DATE) {
        setEndDate(null);
      }
    },
    [recurrence, setCount, setEndDate],
  );

  return (
    <RecurringContentWrapper>
      <VisitTimeSelect fieldName="frequency" items={getFrequencyItems(intl)} required />
      {frequencyValue && (
        <RecurringSelect
          items={getRecurringItems(intl)}
          value={recurrence}
          onChange={handleChange}
          label={label}
          placeholder={placeholder}
        />
      )}
      <RecurrenceEndsOn ends={recurrence} />
    </RecurringContentWrapper>
  );
};
