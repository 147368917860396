import styled, { css } from 'styled-components';
import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';

export const EscortFullName = styled.div`
  line-height: 20px;
  color: ${({ theme }): string => theme.colors.fontColor};
  font-weight: bold;
  margin-bottom: 8px;
`;

export const EscortItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 38px;
`;

export const EscortPanelTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }): string => theme.colors.fontColor};
`;

export const EscortInfo = styled.div`
  display: flex;
  white-space: nowrap;
  flex-direction: column;
  flex-grow: 0;
  width: 100%;
`;

export const Divider = styled.div`
  ${mediaMaxWidth(css`
    width: 100%;
    border-bottom: 0.5px solid ${({ theme }): string => theme.colors.greys.sharedMediumGrey};
    margin: 26px 0;
  `)}
`;
