import { VisitCreationFlow, VisitFormValues } from 'components/visit-form/types';

import { CheckInOptions } from './check-in-option-fields';
import { FormInput } from 'components/visit-form/shared/form-input';
import { InternationalPhoneInput } from 'components/visit-form/steps/visitor-step/visitor-form/international-phone-input';
import { StyledForm } from './visitor-form.style';
import { VisitorContactEmailOrPhone } from 'components/visit-form/steps/visitor-step/visitor-form/visitor-contact-options/visitor-contact-email-phone';
import { VisitorFields } from 'components/visit-form/steps/visitor-step/visitor-form/visitor-fields';
import { useFormikContext } from 'formik';
import { PremierCheckbox } from './premier-checkbox';
import { MultiEmail } from './multi-email';

interface VisitorFormProps {
  index: number;
}

export const VisitorForm = ({ index }: VisitorFormProps): JSX.Element => {
  const {
    values: { visitCreationFlow },
  } = useFormikContext<VisitFormValues>();
  const fieldNamePrefix = `visitors.${index}`;
  return (
    <StyledForm>
      <PremierCheckbox fieldNamePrefix={fieldNamePrefix} />
      <VisitorFields fieldNamePrefix={fieldNamePrefix} />
      {visitCreationFlow === VisitCreationFlow.STANDARD && (
        <InternationalPhoneInput fieldNamePrefix={fieldNamePrefix} uiMetadataKey="visitor_phone" />
      )}
      <VisitorContactEmailOrPhone fieldNamePrefix={fieldNamePrefix} visitCreationFlow={visitCreationFlow} />
      <CheckInOptions fieldNamePrefix={fieldNamePrefix} />
      <FormInput
        fieldName={`${fieldNamePrefix}.checkInInstructions`}
        uiMetadataKey="checkin_instructions"
        translationKey="checkInInstructions"
      />
      <MultiEmail fieldNamePrefix={fieldNamePrefix} />
      <FormInput
        fieldName={`${fieldNamePrefix}.reasonForVisit`}
        uiMetadataKey="reason_for_visit"
        translationKey="reasonForVisit"
      />
    </StyledForm>
  );
};
