import { FormLoader } from 'components/visit-form/shared/visit-form.styles';
import { HostStepFields } from 'components/visit-form/steps/host-step/host-step-fields';
import { StepWrapper } from 'components/visit-form/shared/step-wrapper';
import { buildingState } from 'store/building/selectors';
import { useSelector } from 'react-redux';
import { FormInput } from 'components/visit-form/shared/form-input';
import { useCurrentUser } from 'hooks/use-current-user.hook';

export const SelfHostStep = (): JSX.Element => {
  const { data, loading } = useSelector(buildingState);

  const [currentUser] = useCurrentUser();
  const hostFirstName = `${currentUser?.first_name}`;
  const hostLastName = `${currentUser?.last_name}`;

  if (loading) {
    return <FormLoader />;
  }

  return (
    <StepWrapper titleTranslationId="labels.hostVisit.self.title">
      <FormInput
        fieldName="host.hostName"
        value={`${hostFirstName} ${hostLastName}`}
        translationKey="fullName"
        disabled
      />
      <HostStepFields building={data} />
    </StepWrapper>
  );
};
