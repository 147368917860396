import { FieldWrapper } from 'components/visit-form/shared/field-wrapper';
import { SelectTw } from '@hqo/react-components-library/dist/atoms/select';
import { SelectItems } from 'components/visit-form/shared/types';
import { VisitorRegistrationFlagProperties } from 'store/ui-metadata/types';
import { useField } from 'formik';
import { useSelectData } from './select-wrapper.hooks';
import { ComponentProps } from 'react';

interface SelectWrapperProps extends Partial<ComponentProps<typeof SelectTw>> {
  name: string;
  metadataKey?: keyof VisitorRegistrationFlagProperties;
  items: SelectItems[];
  translationKey: string;
  dataCy?: string;
}

export const SelectWrapper = (props: SelectWrapperProps): JSX.Element | null => {
  const { name, metadataKey, items, translationKey, dataCy, ...otherProps } = props;
  const [{ value, onChange }, { error, touched }] = useField(name);
  const { label, placeholder, hidden, required } = useSelectData(translationKey, metadataKey);

  return hidden ? null : (
    <FieldWrapper touched={touched} error={error}>
      <SelectTw
        label={label}
        name={name}
        value={value}
        items={items}
        dataCy={`${dataCy || translationKey}`}
        placeholder={placeholder}
        onChange={onChange}
        required={required}
        {...otherProps}
      />
    </FieldWrapper>
  );
};
