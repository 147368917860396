import { useDispatch, useSelector } from 'react-redux';

import { EscortState } from './types';
import { OwnerIdParam } from 'shared/types';
import { escortsState as escortsStateSelector } from './selectors';
import { fetchEscorts } from 'store/escorts/actions';
import { useEffect } from 'react';
import { useParams } from 'react-router';

export const useEscortsState = (): EscortState => {
  const { ownerId } = useParams<OwnerIdParam>();
  const dispatch = useDispatch();
  const escortsState = useSelector(escortsStateSelector);

  useEffect(() => {
    if (!escortsState.data) {
      dispatch(fetchEscorts.request({ ownerId }));
    }
  }, [dispatch, escortsState.data, ownerId]);

  return escortsState;
};
