import { Route, Switch, useRouteMatch } from 'react-router';

import { DefaultRedirect } from './default-redirect';
import { IsEditingProvider } from './context/is-editing-provider';
import { FormDefinitionDtoProvider } from './context/form-definition-dto-provider';
import { OwnerParams } from 'shared/types';
import React from 'react';
import { Visit } from 'store/visits/types';
import { VisitFormRoot } from './visit-form-root';
import { useFieldMetadata } from 'store/ui-metadata/hooks';

interface VisitFormProps {
  visit?: Visit | null;
  visitLoading?: boolean;
  isEditMode?: boolean;
}
export const VisitForm: React.FC<VisitFormProps> = ({
  visit,
  visitLoading,
  isEditMode = false,
}: VisitFormProps): JSX.Element => {
  const { path } = useRouteMatch<OwnerParams>();
  const formDefinition = useFieldMetadata('custom_fields');

  return (
    <IsEditingProvider isEditingMode={isEditMode}>
      <FormDefinitionDtoProvider formDefinitionDto={isEditMode ? visit?.custom_form : formDefinition}>
        <Switch>
          <Route path={`${path}/:step`}>
            <VisitFormRoot visit={visit} visitLoading={visitLoading} />
          </Route>
          <Route component={DefaultRedirect} />
        </Switch>
      </FormDefinitionDtoProvider>
    </IsEditingProvider>
  );
};
