import { FormattedMessage } from 'react-intl';
import { Text } from './step-indicator.styles';

interface StepIndicatorProps {
  className?: string;
  index: number;
  total: number;
}
export const StepIndicator = ({ className, index, total }: StepIndicatorProps): JSX.Element => {
  return (
    <Text className={className} data-cy="step-indicator" data-testid="step-indicator">
      <FormattedMessage id="labels.stepOf" values={{ index, total }} />
    </Text>
  );
};
