import { Redirect, useRouteMatch } from 'react-router-dom';

import React from 'react';
import { Step } from './types';
import { useIsFlowStepAvailable } from 'components/visit-form/visit-steps-config/visit-steps-config.hooks';

export const DefaultRedirect: React.FC = (): JSX.Element => {
  const { url } = useRouteMatch();
  const isFlowStepAvailable = useIsFlowStepAvailable();
  const firstStep = isFlowStepAvailable ? Step.Flow : Step.Visitor;

  return <Redirect to={`${url}/${firstStep}`} />;
};
