import styled from 'styled-components';

export const GuestItemsWrapper = styled.div`
  margin-bottom: 32px;
`;

export const GuestItemWrapper = styled.div`
  margin-bottom: 36px;
`;

export const GuestPanelTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: bold;
  color: ${({ theme }): string => theme.colors.fontColor};
`;
