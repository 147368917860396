import { VisitFormValues } from 'components/visit-form/types';
import { VisitTime } from 'components/visit-form/steps/date-step/visit-time/visit-time';
import { Wrapper } from './visit-times.styles';
import { useFormikContext } from 'formik';
import { useVisitTimeOptions } from 'components/visit-form/steps/date-step/visit-times/visit-times.hooks';

interface VisitTimesProps {
  children?: React.ReactNode;
}

export const VisitTimes = ({ children }: VisitTimesProps): JSX.Element => {
  const {
    values: { date },
  } = useFormikContext<VisitFormValues>();
  const { endTimeOptions, startTimeOptions } = useVisitTimeOptions(date.visitDate, date.startTime);

  return (
    <Wrapper>
      <VisitTime
        fieldName="startTime"
        uiMetadataKey="visit_start_time"
        times={startTimeOptions}
        editFieldKey="arrival_date"
      />
      {children}
      <VisitTime
        fieldName="endTime"
        uiMetadataKey="visit_end_time"
        times={endTimeOptions}
        editFieldKey="departure_date"
      />
    </Wrapper>
  );
};
