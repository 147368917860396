import { Building } from 'store/building/types';
import { useFieldMetadata } from 'store/ui-metadata/hooks';
import { FeatureEnablementFlagEnum } from 'store/ui-metadata/types';
import { useHostStepFormData } from './host-step-fields.hooks';
import { SelectWrapper } from 'components/visit-form/shared/select-wrapper';
import { FormInput } from 'components/visit-form/shared/form-input';
import { CheckboxBlock } from '../checkbox-block';

interface HostStepFieldsProps {
  building: Pick<Building, 'name' | 'floors'> | null;
}

export const HostStepFields = ({ building }: HostStepFieldsProps): JSX.Element => {
  const { floorItems, suiteItems, handleChangeFloors } = useHostStepFormData(building);
  const isNotificationTypeVisible = useFieldMetadata('visitor_arrival_notification_preference')?.includes(
    FeatureEnablementFlagEnum.ENABLED_OPTIONAL || FeatureEnablementFlagEnum.ENABLED_REQUIRED,
  );
  return (
    <>
      <FormInput fieldName="host.building" value={building?.name} translationKey="building" disabled />
      {!!floorItems.length && (
        <SelectWrapper
          name="host.floor"
          metadataKey="floor"
          items={floorItems}
          translationKey="floor"
          onChange={handleChangeFloors}
        />
      )}
      {!!suiteItems.length && (
        <SelectWrapper name="host.suite" metadataKey="suite" items={suiteItems} translationKey="suite" />
      )}
      {isNotificationTypeVisible && <CheckboxBlock />}
    </>
  );
};
