import { MapMarker } from './visit-location-info.styles';
import { OpenInMapsButton } from 'components/visit-modal/visit-details/visit-location-info/open-in-maps';
import { VisitDetailsCard } from 'components/visit-modal/visit-details/visit-details-card';
import { buildingState } from 'store/building/selectors';
import { useBuildingLocationInfo } from 'components/visit-modal/visit-details/visit-location-info/visit-location-info.hook';
import { useSelector } from 'react-redux';
import { DetailsBlock } from '../visit-details-card/details-block';

const dataCy = 'visit-location-info';

export interface VisitLocationInfoProps {
  floorName?: string;
  suiteName?: string;
}

export const VisitLocationInfo = ({ floorName, suiteName }: VisitLocationInfoProps): JSX.Element | null => {
  const { data, loading } = useSelector(buildingState);
  const { hasLocationInfo, locationString } = useBuildingLocationInfo(floorName, suiteName, data?.name);
  if (hasLocationInfo) {
    return (
      <VisitDetailsCard
        leftIcon={<MapMarker size="sm" data-cy={`${dataCy}-svg`} />}
        actionItem={!loading && data ? <OpenInMapsButton {...data} /> : null}
      >
        <DetailsBlock dataCy={dataCy} topText={data?.name} bottomText={locationString} />
      </VisitDetailsCard>
    );
  }

  return null;
};
