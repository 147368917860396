import { FormikHelpers, useFormikContext } from 'formik';
import { Step, VisitFormRouteParams, VisitFormValues } from '../../types';
import { createVisit, updateVisit } from 'store/visits/actions';
import { generatePath, useRouteMatch } from 'react-router';
import { useCallback, useEffect } from 'react';

import { mapFormValueToDto } from '../visit-form-root.utils';
import { push } from 'connected-react-router';
import { useBuildingData } from 'store/building/hooks';
import { useCurrentUser } from 'hooks/use-current-user.hook';
import { useDispatch } from 'react-redux';
import { useHostSearchResult } from 'store/hosts/selectors';
import { useNextStepFactory } from '../../visit-steps-config/visit-screens.hooks';
import { useStepsValidationSchema } from '../../visit-steps-config/validations-config.hooks';
import { useTimeZone } from 'shared/hooks';
import { useAvailableSteps } from '../../visit-steps-config/visit-steps-config.hooks';

const getStepUrl = (path: string, params: VisitFormRouteParams, targetStep: Step): string => {
  return generatePath(path.replace(`(${params.step})`, ''), {
    ...params,
    step: targetStep,
  });
};

type VisitSubmitHandler = (values: VisitFormValues, helpers: FormikHelpers<VisitFormValues>) => void;
export const useFormSubmitHandler = (buildingId: string): VisitSubmitHandler => {
  const dispatch = useDispatch();
  const timeZone = useTimeZone();
  const { visitTypes } = useBuildingData();
  const [currentUser] = useCurrentUser();
  const hosts = useHostSearchResult();

  return useCallback(
    (values: VisitFormValues) => {
      const visitDto = mapFormValueToDto(values, timeZone, visitTypes, currentUser, hosts);

      if (visitDto.id) {
        dispatch(updateVisit.request({ params: { buildingId, visitId: visitDto.id }, payload: visitDto }));
      } else {
        dispatch(createVisit.request({ buildingId, visitDto }));
      }
    },
    [timeZone, visitTypes, currentUser, hosts, dispatch, buildingId],
  );
};

export const useSubmitHandler = (forceVisitSubmit?: boolean): VisitSubmitHandler => {
  const dispatch = useDispatch();
  const { params, path } = useRouteMatch<VisitFormRouteParams>();
  const handleFormSubmit = useFormSubmitHandler(params.ownerId);
  const getStepNext = useNextStepFactory();

  return useCallback(
    async (values: VisitFormValues, helpers: FormikHelpers<VisitFormValues>): Promise<void> => {
      const nextStep = getStepNext(values);

      if (!forceVisitSubmit && nextStep) {
        helpers.resetForm({ values });
        dispatch(push(getStepUrl(path, params, nextStep)));
      } else {
        handleFormSubmit(values, helpers);
      }
    },
    [getStepNext, forceVisitSubmit, dispatch, path, params, handleFormSubmit],
  );
};

const getPreviousSteps = (availableSteps: Step[], currentStep: Step): Step[] => {
  const currentStepIndex = availableSteps.indexOf(currentStep);

  return currentStepIndex > 0 ? availableSteps.slice(0, currentStepIndex) : [];
};

export const useValidatePreviousSteps = (bypassValidation?: boolean): void => {
  const dispatch = useDispatch();
  const { params, path } = useRouteMatch<VisitFormRouteParams>();
  const { values } = useFormikContext<VisitFormValues>();
  const availableSteps = useAvailableSteps();
  const previousSteps = getPreviousSteps(availableSteps, params.step);
  const stepsValidationSchema = useStepsValidationSchema(previousSteps);

  useEffect(() => {
    if (!bypassValidation) {
      stepsValidationSchema.validate(values).catch(() => {
        dispatch(push(getStepUrl(path, params, availableSteps[0])));
      });
    }
  }, [availableSteps, bypassValidation, dispatch, params, params.step, path, stepsValidationSchema, values]);
};
