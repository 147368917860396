import { useCallback } from 'react';
import { VisitCreationFlow, VisitFormValues } from 'components/visit-form/types';

import { AddVisitorButton } from './add-visitor-button/add-visitor-button';
import { VisitorsList } from './visitors-list/visitors-list';
import { useCollapsedVisitors } from './group-visitors.hook';
import { useFormikContext } from 'formik';
import { visitorInitialValue } from 'components/visit-form/visit-form-root/hooks/use-initial-values.hook';

interface AddVisitorProps {
  currentIndex: number;
}

export const GroupVisitors = ({ currentIndex }: AddVisitorProps): JSX.Element => {
  const {
    values: { visitors: visitorsValue, visitCreationFlow },
    isValid,
    isValidating,
    setFieldValue,
  } = useFormikContext<VisitFormValues>();

  const collapsedVisitors = useCollapsedVisitors(visitorsValue, currentIndex);

  const addAnotherGuest = useCallback(() => {
    const currentVisitorsData = [...visitorsValue, visitorInitialValue];
    setFieldValue('visitors', currentVisitorsData);
  }, [setFieldValue, visitorsValue]);
  const buttonText =
    visitCreationFlow === VisitCreationFlow.STANDARD ? 'button.addVisitorButton' : 'button.addVipVisitorButton';

  return (
    <>
      <AddVisitorButton
        text={buttonText}
        clickHandle={addAnotherGuest}
        isStepValid={isValid && !isValidating}
        dataCy="add-visitor-button"
      />
      <VisitorsList visitors={collapsedVisitors} />
    </>
  );
};
