import React, { useMemo } from 'react';

import { VisitFormRouteParams } from 'components/visit-form/types';
import { StepButton } from 'components/visit-form/shared/step-wrapper/step-button';
import { StepTitle } from 'components/visit-form/shared/step-wrapper/step-title';
import { VisitFormHeader } from 'components/visit-form/shared/step-wrapper/visit-form-header';
import { useParams } from 'react-router';
import { useStepNumber } from 'components/visit-form/step.hooks';
import { ContentWrapper } from './step-wrapper.styles';

interface StepWrapperProps {
  children: React.ReactNode;
  titleTranslationId: string;
  translationIdButton?: string;
  isEditMode?: boolean;
}
export const StepWrapper = ({
  children,
  titleTranslationId,
  translationIdButton,
  isEditMode,
}: StepWrapperProps): JSX.Element => {
  const stepNumber = useStepNumber();
  const { step } = useParams<VisitFormRouteParams>();
  const translationValues = useMemo(() => ({ index: stepNumber + 1 }), [stepNumber]);

  return (
    <>
      <VisitFormHeader isEditMode={isEditMode} />
      <ContentWrapper>
        <StepTitle translationId={titleTranslationId} dataCy={`create-visit-${step}-step-title`} />
        {children}
        <StepButton
          isEditMode={isEditMode}
          translationId={translationIdButton || 'buttons.continueToStep'}
          translationValues={translationValues}
          dataCy={`submit-${step}-step-button`}
        />
      </ContentWrapper>
    </>
  );
};
