import moment from 'moment';

import { useField } from 'formik';
import { DateField } from './date-field';

interface Props {
  startDateFieldName?: string;
  fieldName?: string;
}

export const VisitEndDate = ({ startDateFieldName = 'visitDate', fieldName = 'visitEndDate' }: Props): JSX.Element => {
  const [{ value: startDateValue }] = useField<Date | null>(`date.${startDateFieldName}`);

  return <DateField fieldName={fieldName} minDate={moment(startDateValue)} />;
};
