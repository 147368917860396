import {
  ConfirmationDescription,
  ConfirmationTitle,
  Confirmation as ConfirmationWrapper,
  IconWrapper,
} from './confirmation-modal.styles';

import { ExclamationMarkSolid } from '@hqo/react-components-library/dist/icons';
import { FormattedMessage } from 'react-intl';

interface ConfirmationProps {
  titleTranslationId: string;
  descriptionTranslationId?: string;
}

export const Confirmation = ({ titleTranslationId, descriptionTranslationId }: ConfirmationProps): JSX.Element => (
  <ConfirmationWrapper>
    <IconWrapper size="sm">{ExclamationMarkSolid}</IconWrapper>
    <ConfirmationTitle>
      <FormattedMessage id={titleTranslationId} />
    </ConfirmationTitle>
    {descriptionTranslationId && (
      <ConfirmationDescription>
        <FormattedMessage id={descriptionTranslationId} />
      </ConfirmationDescription>
    )}
  </ConfirmationWrapper>
);
