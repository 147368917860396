import React, { useCallback } from 'react';
import { useFieldTranslations } from 'hooks/use-field-traslations.hooks';
import { SelectItems } from 'components/visit-form/shared/types';
import { useField } from 'formik';
import { useUIMetadataFieldRequirements } from 'components/visit-form/shared/form.hooks';
import { SelectFieldProps } from 'components/visit-form/steps/visitor-step/select-field/select-field.interface';

const useChangeHandler = (fieldName: string, options: SelectItems[]): React.ChangeEventHandler<HTMLInputElement> => {
  const [, , { setValue }] = useField(fieldName);

  return useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const selectedButton = options.find((button) => button.value === event.target.value);
      setValue(selectedButton?.value);
    },
    [setValue, options],
  );
};

interface SelectFieldOptions {
  label: string;
  placeholder: string;
  value: string;
  hidden: boolean;
  required: boolean;
  handleChange: React.ChangeEventHandler<HTMLInputElement>;
}

export const useSelectFieldOptions = ({
  fieldNamePrefix,
  fieldName,
  options,
  uiMetadataKey,
}: SelectFieldProps): SelectFieldOptions => {
  const formFieldName = `${fieldNamePrefix}.${fieldName}`;
  const { label, placeholder } = useFieldTranslations(fieldName);
  const [{ value }] = useField(formFieldName);
  const { hidden, required } = useUIMetadataFieldRequirements(uiMetadataKey);
  const handleChange = useChangeHandler(formFieldName, options);

  return {
    label,
    placeholder,
    value,
    hidden,
    required,
    handleChange,
  };
};
