import styled from 'styled-components';

export const ProgressBarWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 3px;

  background-color: ${({ theme }) => theme.colors.greys.sharedGreyBackground};
`;

export const ProgressIndicator = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 0;
  background-color: ${({ theme }) => theme.colors.secondary};
`;
