import styled, { css } from 'styled-components';
import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';

export const VisitDetailsWrapper = styled.div`
  max-width: 70%;

  ${mediaMaxWidth(css`
    max-width: 90vw;
  `)}
`;

export const VisitDetailsTitle = styled.div`
  color: ${({ theme }): string => theme.colors.fontColor};
  line-height: 24px;
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 12px;
`;

export const VisitDetailsDescriptionWrapper = styled.span`
  display: block;
  font-size: 14px;
  color: ${({ theme }): string => theme.colors.fontColor};
  line-height: 16px;
  margin-bottom: 18px;
`;
