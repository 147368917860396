import { Container, VisitDetailsInfo, CircularArrowsIcon, RecurrenceWrapper } from './visit-details-card.styles';

import React from 'react';

interface VisitDetailsProps {
  leftIcon?: React.ReactNode;
  recurringText?: string;
  rightIcon?: React.ReactNode;
  actionItem?: React.ReactNode;
  cancelAction?: React.ReactNode;
  children?: React.ReactNode;
}

export const VisitDetailsCard = ({
  leftIcon,
  recurringText,
  rightIcon,
  actionItem,
  cancelAction,
  children,
}: VisitDetailsProps): JSX.Element => (
  <Container>
    {leftIcon}
    <VisitDetailsInfo>
      {children}
      {recurringText && (
        <RecurrenceWrapper>
          <CircularArrowsIcon size="sm" variant="primary" />
          {recurringText}
        </RecurrenceWrapper>
      )}
      {actionItem}
      {cancelAction}
    </VisitDetailsInfo>
    {rightIcon}
  </Container>
);
