import { FormInput } from 'components/visit-form/shared/form-input/form-input';
import { FormLoader } from 'components/visit-form/shared/visit-form.styles';
import { SelectWrapper } from 'components/visit-form/shared/select-wrapper/select-wrapper';
import { StepWrapper } from 'components/visit-form/shared/step-wrapper';
import { useEscortItems } from './tenant-provided-escort.hook';
import { StepProps } from 'components/visit-form/steps/step.props.interface';

export const TenantProvidedEscort = ({ isEditMode }: StepProps): JSX.Element => {
  const { items, loading } = useEscortItems();

  if (loading) {
    return <FormLoader />;
  }

  return (
    <StepWrapper titleTranslationId="labels.newVisit.escortStep.title" isEditMode={isEditMode}>
      <SelectWrapper name="escort.id" translationKey="names" items={items} dataCy="names" required={false} />
      <FormInput fieldName="escort.instructions" translationKey="instructions" />
    </StepWrapper>
  );
};
