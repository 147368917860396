import { GuestInfo, GuestFullName, GuestItemWrapper, PremierAccess } from './guest-item.styles';

import { Visitor } from 'store/visits/types';
import { useIntl } from 'react-intl';

interface GuestItemProps {
  visitor: Visitor;
  dataCy: string;
}

export const GuestItem = ({ visitor, dataCy }: GuestItemProps): JSX.Element => {
  const intl = useIntl();

  return (
    <GuestItemWrapper>
      <GuestFullName data-cy={dataCy}>{`${visitor?.first_name} ${visitor?.last_name}`}</GuestFullName>
      {visitor.email && <GuestInfo>{visitor.email}</GuestInfo>}
      {visitor.phone && <GuestInfo>{visitor.phone}</GuestInfo>}
      {visitor.is_premier && <PremierAccess>{intl.formatMessage({ id: 'labels.isPremier' })}</PremierAccess>}
    </GuestItemWrapper>
  );
};
