import { ArrowDownMaterial } from '@hqo/react-components-library/dist/icons';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.fontColor};
  display: flex;
  align-items: center;

  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
`;

export const BackIcon = styled(ArrowDownMaterial)`
  color: ${({ theme }) => theme.colors.fontColor};
  transform: rotate(90deg);
  margin-right: 4px;
`;
