import { useLocale, useTimeZone } from 'shared/hooks';

import { parseTime } from 'components/visit-form/visit-form-root/visit-form-root.utils';
import { useMemo } from 'react';

export interface VisitTimeItem {
  label: string;
  value: string;
}

const startOfDay = '00:00';
const finalStartTime = '23:30';
const finalStopTime = '23:45';
const timeIntervalInSeconds = 15 * 60;

const roundMinuteToInterval = (minute: number, interval: number): number => Math.ceil(minute / interval) * interval;
const formatTimeValue = (dateTime: Date, timeZone?: string): string =>
  dateTime.toLocaleTimeString(undefined, {
    timeZone,
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
  });
const formatTimeLabel = (dateTime: Date, locale: string): string =>
  dateTime.toLocaleTimeString(locale, { timeStyle: 'short' });

const isToday = (someDate: Date) => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

const getStartTime = (date: Date | null, timeZone: string): string => {
  if (date && isToday(date)) {
    const currentDateTime = new Date();
    currentDateTime.setMinutes(roundMinuteToInterval(currentDateTime.getMinutes(), 15));

    return formatTimeValue(currentDateTime, timeZone);
  }

  return startOfDay;
};

const getTimeBounds = (
  date: Date | null,
  timeZone: string,
  startTime?: string | null | undefined,
): [string, string] => {
  if (startTime) {
    const [hours, minutes] = parseTime(startTime);
    const startDateTime = new Date();
    startDateTime.setHours(hours, minutes, 0);
    startDateTime.setSeconds(startDateTime.getSeconds() + timeIntervalInSeconds);

    return [formatTimeValue(startDateTime), finalStopTime];
  }

  return [getStartTime(date, timeZone), finalStartTime];
};

export const usePossibleTimeOptions = (): VisitTimeItem[] => {
  const locale = useLocale();

  return useMemo(() => {
    const timeOptions = [];
    const dateTime = new Date();
    const startDate = dateTime.getDate();
    dateTime.setHours(0, 0, 0);

    while (startDate === dateTime.getDate()) {
      timeOptions.push({
        value: formatTimeValue(dateTime),
        label: formatTimeLabel(dateTime, locale),
      });

      dateTime.setSeconds(dateTime.getSeconds() + timeIntervalInSeconds);
    }

    return timeOptions;
  }, [locale]);
};

export const useTimeItems = (
  allTimeOptions: VisitTimeItem[],
  visitDate: Date | null,
  startTime: string | null,
): VisitTimeItem[] => {
  const timeZone = useTimeZone();

  return useMemo(() => {
    const [startTimeItem, endTimeItem] = getTimeBounds(visitDate, timeZone, startTime);
    const startTimeIndex = allTimeOptions.findIndex(({ value }) => value === startTimeItem);
    const endTimeIndex = allTimeOptions.findIndex(({ value }) => value === endTimeItem);

    return allTimeOptions.slice(startTimeIndex, endTimeIndex + 1);
  }, [visitDate, timeZone, startTime, allTimeOptions]);
};

interface VisitTimeOptions {
  startTimeOptions: VisitTimeItem[];
  endTimeOptions: VisitTimeItem[];
}
export const useVisitTimeOptions = (visitDate: Date | null, startTime: string | null): VisitTimeOptions => {
  const allTimeOptions = usePossibleTimeOptions();

  const startTimeOptions = useTimeItems(allTimeOptions, visitDate, null);
  const endTimeOptions = useTimeItems(allTimeOptions, visitDate, startTime);

  return {
    startTimeOptions,
    endTimeOptions,
  };
};
