import { DynamicFormDto, FieldType } from '@hqo/dynamic-forms-sdk/types';
import { DynamicFormDataDto } from '@hqo/dynamic-forms-sdk/types/form/form-data.types';
import { useFormDefinition } from 'hooks/use-form-definition.hooks';
import { FormField } from '@hqo/dynamic-forms-sdk/form';
import { DateTimeFormatter, useDateTimeFormatters } from 'shared/hooks';

export interface FormAnswer {
  question: string;
  answer: string;
}

const emptyFieldValues = new Set([null, undefined]);

const prepareFieldValue = (formatDate: DateTimeFormatter, field: FormField) => {
  const stringValue = field.value.toString();

  return field.type === FieldType.DATE ? formatDate(stringValue) : stringValue;
};

export const useFormAnswers = (
  customForm: DynamicFormDto,
  formData: DynamicFormDataDto,
): [FormAnswer[] | null, Error | null] => {
  const { formatDate } = useDateTimeFormatters({
    dateFormat: { dateStyle: 'long' },
  });

  const [form, error] = useFormDefinition(customForm, formData);

  if (error) {
    return [null, error];
  }

  const fields = form.steps.flatMap((step) => step.fields);
  const formAnswers = fields
    .filter((field) => !emptyFieldValues.has(field.value))
    .map((field) => ({ question: field.label, answer: prepareFieldValue(formatDate, field) }));

  return [formAnswers, null];
};
