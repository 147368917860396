import { ROOT_PATH, VISITS_SUB_PATH } from 'shared/consts';

import { Modal } from 'components/shared/modal';
import { VisitModal } from 'components/visit-modal';
import { useNavigate } from 'hooks/use-navigate-handler.hook';
import { useParams } from 'react-router';
import { useFieldMetadata } from '../../store/ui-metadata/hooks';

export const VisitDetailsPage = (): JSX.Element => {
  const { visitId } = useParams<{ visitId: string }>();

  const viewVisitsEnabled = useFieldMetadata('view_visits_enabled');
  const goBackPath = viewVisitsEnabled
    ? `${ROOT_PATH}/${VISITS_SUB_PATH}/:visitUiType`
    : `${ROOT_PATH}/${VISITS_SUB_PATH}/new/visitor`;

  const handleClose = useNavigate(goBackPath);

  return (
    <Modal onClose={handleClose} variant="wide" hideMobileCloseButton={!viewVisitsEnabled}>
      <VisitModal visitId={visitId} />
    </Modal>
  );
};
