import { useField } from 'formik';
import { CheckboxHqoStyles } from './checkbox-block.styles';
import { CheckboxHqo } from '@hqo/react-components-library/dist/molecules/checkbox-hqo';
import { VisitorRegistrationFlagProperties } from 'store/ui-metadata/types';
import { useUIMetadataFieldRequirements } from 'components/visit-form/shared/form.hooks';

interface CheckboxItemProps {
  fieldName: string;
  label: string;
  uiMetadataKey?: keyof VisitorRegistrationFlagProperties;
}

export const CheckboxItem = ({ fieldName, label, uiMetadataKey }: CheckboxItemProps): JSX.Element | null => {
  const [{ value, onChange }] = useField(`host.notificationType.${fieldName}`);
  const { hidden } = useUIMetadataFieldRequirements(uiMetadataKey);

  if (hidden) {
    return null;
  }

  return (
    <CheckboxHqoStyles checked={value}>
      <CheckboxHqo
        id={`note-checkbox-${fieldName}`}
        label={label}
        className="note-checkbox"
        name={`host.notificationType.${fieldName}`}
        checked={value}
        onChange={onChange}
        data-cy={`${fieldName}-type-notification`}
      />
    </CheckboxHqoStyles>
  );
};
