import { Step, VisitFormValues } from '../types';

import { getAvailableSteps, isFlowStepAvailable } from './visit-steps-config';
import { useFieldMetadata } from 'store/ui-metadata/hooks';
import { useFormikContext } from 'formik';
import { useMemo } from 'react';
import { useBuildingData } from 'store/building/hooks';

export const useIsFlowStepAvailable = (): boolean => {
  const isVipEnabled = useFieldMetadata('vip');
  const isEscortEnabled = useFieldMetadata('escort');

  return isFlowStepAvailable(isVipEnabled, isEscortEnabled);
};

export const useAvailableSteps = (): Step[] => {
  const { values } = useFormikContext<VisitFormValues>();
  const isEscortEnabled = useFieldMetadata('escort');
  const isVipEnabled = useFieldMetadata('vip');
  const isHostsEnabled = useFieldMetadata('alt_hosts_enabled');
  const { floors: availableFloors } = useBuildingData();
  const isCustomFieldsStepAvailable = !!useFieldMetadata('custom_fields');

  return useMemo(
    () =>
      getAvailableSteps({
        values,
        isVipEnabled,
        isEscortEnabled,
        isHostsEnabled,
        availableFloors,
        isCustomFieldsStepAvailable,
      }),
    [values, isVipEnabled, isEscortEnabled, isHostsEnabled, availableFloors, isCustomFieldsStepAvailable],
  );
};

export const useCountedSteps = (): Step[] => {
  const availableSteps = useAvailableSteps();

  return useMemo(() => availableSteps.filter((step) => step !== Step.Flow), [availableSteps]);
};
