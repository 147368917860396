import { Item } from '@hqo/react-components-library';
import { IntlShape, useIntl } from 'react-intl';
import { VisitTimeItem } from 'components/visit-form/steps/date-step/visit-times/visit-times.hooks';
import { HqoWheelPicker } from '@hqo/react-components-library/dist/molecules/hqo-wheel-picker';

interface PickerProps {
  items: Item<string>[];
  label?: string;
  placeholder?: string;
  required?: boolean;
  value: string;
  onChange: (value: string) => void;
  error?: string;
}

export const generatePickerItem =
  (intl: IntlShape, translationPath: string) =>
  (value: string): VisitTimeItem => ({
    value,
    label: intl.formatMessage({ id: `${translationPath}.${value}` }),
  });

export const Picker = ({ items, label, placeholder, required, value, onChange, error }: PickerProps): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <HqoWheelPicker
      items={items}
      label={label}
      placeholder={placeholder}
      doneButtonLabel={formatMessage({ id: 'done' })}
      required={required}
      value={value}
      onChange={onChange}
      error={error}
    />
  );
};
