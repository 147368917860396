import { FormattedMessage } from 'react-intl';
import { GOOGLE_PLACE_URL } from 'shared/consts/urls';
import { OpenInMapsAnchor } from './open-in-maps.styles';

interface OpenInMapsProps {
  address?: string;
  name?: string;
  city?: string;
}
const getLocationString = (address?: string, name?: string, city?: string): string =>
  `${GOOGLE_PLACE_URL}${address ?? name} ${city ?? ''}`;

export const OpenInMapsButton = ({ address, name, city }: OpenInMapsProps): JSX.Element | null => {
  if ((name && city) || address) {
    return (
      <OpenInMapsAnchor href={getLocationString(address, name, city)} target="_blank">
        <FormattedMessage id="button.openInMaps" />
      </OpenInMapsAnchor>
    );
  }

  return null;
};
