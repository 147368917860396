import styled from 'styled-components';
import { FormField } from '@hqo/react-components-library/dist/atoms/form-field';

export const StyledForm = styled.div`
  & > * {
    margin-bottom: 30px;
  }
`;

export const FormFieldStyled = styled(FormField)`
  .wrapper.field .input {
    padding-left: 4px;

    &:not(:first-child) {
      margin-left: 8px;
      padding: 0;

      &:has(:not(:focus)[value='']) {
        width: 0;
        padding: 0;
      }
    }
  }
`;
