import { ROOT_PATH, VISITS_SUB_PATH } from 'shared/consts';

import { CancelVisitorFormConfirmation } from './cancel-visitor-form-confirmation';
import { Modal } from 'components/shared/modal';
import { ModalFormLayout } from './visit-form-modal.styles';
import React from 'react';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks/use-boolean-state';
import { useFormikContext } from 'formik';
import { useNavigate } from 'hooks/use-navigate-handler.hook';
import { useStepNumber } from 'components/visit-form/step.hooks';
import { useFieldMetadata } from '../../../../store/ui-metadata/hooks';

interface NewVisitFormModalProps {
  children: React.ReactNode;
}

export const VisitFormModal = ({ children }: NewVisitFormModalProps): JSX.Element => {
  const handleClose = useNavigate(`${ROOT_PATH}/${VISITS_SUB_PATH}`);
  const { value: isConfirmVisible, setTrue: showConfirm, setFalse: hideConfirm } = useBooleanState();
  const { dirty } = useFormikContext();
  const stepNumber = useStepNumber();
  const shouldShowCancelConfirm = dirty || stepNumber > 1;
  const viewVisitsEnabled = useFieldMetadata('view_visits_enabled');

  return (
    <Modal
      onClose={shouldShowCancelConfirm ? showConfirm : handleClose}
      variant="normal"
      hideMobileCloseButton
      withExitButton={!!viewVisitsEnabled}
    >
      <ModalFormLayout>{children}</ModalFormLayout>
      {isConfirmVisible && <CancelVisitorFormConfirmation onCancel={hideConfirm} onConfirm={handleClose} />}
    </Modal>
  );
};
