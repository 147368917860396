import { ErrorMessage, FieldWrapperStyled } from './field-wrapper.styles';

import { FormattedMessage } from 'react-intl';
import React from 'react';

interface FieldWrapperProps {
  error?: string | null;
  touched?: boolean;
  children: React.ReactNode;
}
export const FieldWrapper = ({ error, touched, children }: FieldWrapperProps): JSX.Element => (
  <FieldWrapperStyled>
    {children}
    {touched && error && (
      <ErrorMessage>
        <FormattedMessage id={error} defaultMessage={error} />
      </ErrorMessage>
    )}
  </FieldWrapperStyled>
);
