import { Escort } from 'store/escorts/types';
import { SelectItems } from 'components/visit-form/shared/types';
import { useEscortsState } from 'store/escorts/hooks';

interface escortItemsHook {
  items: SelectItems[];
  loading: boolean;
}

function prepareEscortItems(escorts: Escort[] | null): SelectItems[] | [] {
  return escorts?.map((escort) => ({ label: `${escort.first_name} ${escort.last_name}`, value: escort.id })) ?? [];
}

export const useEscortItems = (): escortItemsHook => {
  const { data, loading } = useEscortsState();

  return { items: prepareEscortItems(data), loading };
};
