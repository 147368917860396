import { IsEditingMode } from './is-editing.context';
import React from 'react';

interface IsEditingProviderProps {
  children: React.ReactNode;
  isEditingMode: boolean;
}

export const IsEditingProvider = ({ children, isEditingMode }: IsEditingProviderProps): JSX.Element => {
  return <IsEditingMode.Provider value={isEditingMode}>{children}</IsEditingMode.Provider>;
};
