import { ObjectSchema, object } from 'yup';

import { ObjectShape } from 'yup/lib/object';
import { Step } from '../types';
import { useBuildingState } from 'store/building/hooks';
import { useMemo } from 'react';
import { useTimeZone } from 'shared/hooks';
import { useUiMetadata } from 'store/ui-metadata/hooks';
import { validationSchemaBuilders } from './validations-config';

export const useValidationSchema = (currentStep: Step): ObjectSchema<ObjectShape> => {
  const uiMetadata = useUiMetadata();
  const building = useBuildingState();
  const timeZone = useTimeZone();

  return useMemo(() => {
    const schemaBuilder = validationSchemaBuilders[currentStep];

    return schemaBuilder(uiMetadata, building, timeZone);
  }, [uiMetadata, currentStep, building, timeZone]);
};

export const useStepsValidationSchema = (steps: Step[]): ObjectSchema<ObjectShape> => {
  const uiMetadata = useUiMetadata();
  const building = useBuildingState();
  const timeZone = useTimeZone();

  return useMemo(() => {
    return steps.reduce((schema, step) => {
      const schemaBuilder = validationSchemaBuilders[step];

      return schema.concat(schemaBuilder(uiMetadata, building, timeZone));
    }, object());
  }, [uiMetadata, steps, building, timeZone]);
};
