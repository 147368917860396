import { ButtonWrapper, VisitDetailsWrapper, VisitModalWrapper } from './visit-modal.styles';

import { VisitDetails } from 'components/visit-modal/visit-details';
import { VisitStatus } from 'components/visit-modal/visit-status';
import { Visit, VisitStatus as VisitStatusEnum } from 'store/visits/types';
import { VisitDetailsButton } from './visit-details-button';

interface VisitModalContentProps {
  data: Visit | null;
}

export const VisitModalContent = ({ data }: VisitModalContentProps): JSX.Element => {
  return (
    <VisitModalWrapper>
      <VisitDetailsWrapper>
        <VisitStatus
          arrivalDate={data?.arrival_date}
          specialVisitType={data?.special_visit_type}
          visitStatus={data?.visit_status}
        />
        <VisitDetails data={data} />
      </VisitDetailsWrapper>
      {data?.visit_status !== VisitStatusEnum.CANCELED && (
        <ButtonWrapper>
          <VisitDetailsButton />
        </ButtonWrapper>
      )}
    </VisitModalWrapper>
  );
};
