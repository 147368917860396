import { useCallback, useContext, useEffect, useMemo } from 'react';
import { HqoClientSdk } from '@hqo/hqo-miniapp-client-sdk';
import { MiniappSdkContext } from 'components/miniapp-sdk-provider/miniapp-sdk.context';
import { Handler } from '@hqo/hqo-miniapp-client-sdk/dist/sdk/types/sdk.interface';
import { SdkEvents } from '@hqo/hqo-miniapp-client-sdk/dist/sdk/types/sdk-handler.types';
import { DEFAULT_SDK_INIT_TIMEOUT_IN_SECONDS } from 'shared/consts';

export const useMiniappSdkClient = (appUuid?: string): HqoClientSdk | null =>
  useMemo(
    () =>
      appUuid
        ? new HqoClientSdk({
            app_uuid: appUuid,
            develop: false,
            init_timeout: DEFAULT_SDK_INIT_TIMEOUT_IN_SECONDS,
          })
        : null,
    [appUuid],
  );

export const useMiniappSdk = (): HqoClientSdk | null => useContext(MiniappSdkContext);

export const useMiniappSdkEventHandler = (
  eventName: SdkEvents,
  handler: Handler,
  handlerDependencies: unknown[],
): void => {
  const client = useMiniappSdk();
  const eventHandler = useCallback(handler, [handler, ...handlerDependencies]);

  useEffect(() => {
    client?.on(eventName, eventHandler);
  }, [eventName, eventHandler, client]);
};
