import { useDateTimeFormatters, useVisitTime } from 'shared/hooks';
import { TimeSlotData } from '../visit-details-card/time-slot';

const timeDelimeter = ' - ';

export const useTimeSlots = (arrivalDate?: Date, departureDate?: Date): TimeSlotData[] => {
  const { formatDate, formatTime } = useDateTimeFormatters({
    dateFormat: { dateStyle: 'long' },
  });
  const arrivalDateString = formatDate(arrivalDate);
  const departureDateString = formatDate(departureDate);
  const arrivalTimeString = formatTime(arrivalDate);
  const departureTimeString = departureDate ? formatTime(departureDate) : '';
  const visitTime = useVisitTime(arrivalDate, departureDate, arrivalTimeString, departureTimeString);

  if (arrivalDateString === departureDateString) {
    return [{ date: arrivalDateString, time: visitTime.join(timeDelimeter) }];
  }

  const [startTime, endTime] = visitTime;

  return [
    { date: arrivalDateString, time: startTime },
    { date: departureDateString, time: endTime },
  ];
};
