import { useMemo } from 'react';
import { VisitorFormValues } from 'components/visit-form/types';

export const useTitleTranslationValues = (visitors: VisitorFormValues[]): Record<string, string> =>
  useMemo(
    () => ({
      guestFullName: `${visitors[0].firstName} ${visitors[0].lastName}`,
    }),
    [visitors],
  );
