import { useSelector } from 'react-redux';
import { useMemo } from 'react';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';
import { DynamicForm } from '@hqo/dynamic-forms-sdk/form';

import { configSelector } from 'store/config/selectors';
import { useFormDefinition } from 'hooks/use-form-definition.hooks';
import { VisitFormValues } from 'components/visit-form/types';
import { useFormDefinitionDto } from 'components/visit-form/context/form-definition-dto.hook';

export const useDynamicFormDefinition = (): [DynamicForm | null, Error | null] => {
  const formDto = useFormDefinitionDto();
  const {
    values: { custom_fields: customFields },
  } = useFormikContext<VisitFormValues>();

  const formDefinition = useFormDefinition(formDto, customFields);

  return customFields ? formDefinition : [null, null];
};

export const useDynamicFormsLocalizationValues = () => {
  const intl = useIntl();

  return useMemo(
    () => ({
      continueButton: intl.formatMessage({ id: 'buttons.continue' }),
      skipButton: intl.formatMessage({ id: 'buttons.skip' }),
      submitButton: intl.formatMessage({ id: 'buttons.submitNewRegistration' }),
    }),
    [intl],
  );
};

export const useDynamicFormsLocaleConfig = () => {
  const config = useSelector(configSelector);

  return useMemo(() => ({ defaultCurrencyCode: 'USD', locale: config.locale }), [config.locale]);
};
