import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
`;

export const SlotContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: auto;
  padding-right: 10px;
`;
