import { CheckboxWrapper, FieldLabelStyles } from './checkbox-block.styles';
import { CheckboxItem } from './checkbox-item';
import { useIntl } from 'react-intl';
import { VisitorRegistrationFlagProperties } from 'store/ui-metadata/types';

interface CheckboxField {
  fieldName: string;
  uiMetadataKey: keyof VisitorRegistrationFlagProperties;
}

const checkboxFieldsData: CheckboxField[] = [
  { fieldName: 'notification', uiMetadataKey: 'push' },
  { fieldName: 'emailMessage', uiMetadataKey: 'email' },
  { fieldName: 'textMessage', uiMetadataKey: 'sms' },
];

export const CheckboxBlock = (): JSX.Element => {
  const { formatMessage } = useIntl();
  return (
    <CheckboxWrapper>
      <FieldLabelStyles label={formatMessage({ id: 'fields.notificationType.label' })} />
      {checkboxFieldsData.map((field) => (
        <CheckboxItem
          key={field.fieldName}
          fieldName={field.fieldName}
          uiMetadataKey={field.uiMetadataKey}
          label={formatMessage({ id: `labels.visitNotificationType.${field.fieldName}` })}
        />
      ))}
    </CheckboxWrapper>
  );
};
