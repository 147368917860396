import { VisitModalError } from 'components/visit-modal/visit-modal-error';
import { VisitModalLoader } from 'components/visit-modal/visit-modal-loader';
import { useVisitState } from 'store/visits/hooks';
import { useFieldMetadata } from '../../store/ui-metadata/hooks';
import { VisitModalContent } from 'components/visit-modal/visit-modal-content';

interface VisitModalProps {
  visitId: string;
}

// eslint-disable-next-line max-lines-per-function
export const VisitModal = ({ visitId }: VisitModalProps): JSX.Element => {
  const viewVisitsEnabled = useFieldMetadata('view_visits_enabled');
  const { data, loading, error } = useVisitState(visitId, !viewVisitsEnabled);

  if (loading) {
    return <VisitModalLoader />;
  }

  if (error) {
    return <VisitModalError />;
  }

  return <VisitModalContent data={data} />;
};
