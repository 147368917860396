import { ProgressBarWrapper, ProgressIndicator } from './progress-bar.styles';

interface ProgressBarProps {
  className?: string;
  progress: number;
}

export const ProgressBar = ({ progress, className }: ProgressBarProps): JSX.Element => (
  <ProgressBarWrapper className={className}>
    <ProgressIndicator
      style={{ width: `${progress}%` }}
      data-cy="progress-indicator"
      data-testid="progress-indicator"
    />
  </ProgressBarWrapper>
);
