import { VisitorFormValues } from 'components/visit-form/types';
import { VisitorsListItem } from './visitors-list-item';
import { VisitorsListStyles } from './visitors-list-item.styles';

interface VisitorsListProps {
  visitors: VisitorFormValues[];
}

export const VisitorsList = ({ visitors }: VisitorsListProps): JSX.Element => (
  <VisitorsListStyles data-testid="visitors-list">
    {visitors.map((visitor, index) => (
      <VisitorsListItem key={`${visitor.firstName}-${visitor.lastName}`} visitor={visitor} currentIndex={index} />
    ))}
  </VisitorsListStyles>
);
