import { FormattedMessage } from 'react-intl';
import { Title } from './step-title.styles';

interface StepTitleProps {
  translationId: string;
  translationValues?: Record<string, string | number>;
  dataCy?: string;
}

export const StepTitle = ({ translationId, dataCy, translationValues }: StepTitleProps): JSX.Element => (
  <Title data-cy={dataCy} data-testid={dataCy}>
    <FormattedMessage id={translationId} values={translationValues} />
  </Title>
);
