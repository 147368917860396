import { useMatchDates } from './visit-date.hooks';
import { DateField } from './date-field';

interface Props {
  fieldName?: string;
}

export const VisitDate = ({ fieldName = 'visitDate' }: Props): JSX.Element => {
  const matchDates = useMatchDates();

  return <DateField onChange={matchDates} fieldName={fieldName} />;
};
