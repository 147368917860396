import { VisitFormRouteParams, VisitFormValues } from 'components/visit-form/types';
import { generatePath, useRouteMatch } from 'react-router';

import { useFormikContext } from 'formik';
import { useNextStepFactory } from 'components/visit-form/visit-steps-config/visit-screens.hooks';

export const useNextStepUrl = (): string | null => {
  const { path, params } = useRouteMatch<VisitFormRouteParams>();
  const { values } = useFormikContext<VisitFormValues>();
  const getNextStep = useNextStepFactory();
  const nextStep = getNextStep(values);

  if (!nextStep) {
    return null;
  }

  return generatePath(path.replace(new RegExp(`\\(${params.step}\\).+`), ''), {
    ...params,
    step: nextStep,
  });
};
