import { ContinueButtonStyled, ContinueButtonWrapper } from './continue-button.styles';

import { useIntl } from 'react-intl';
import { useRouteMatch } from 'react-router';

interface ContinueButtonProps {
  errorTranslationId?: string;
  translationId: string;
  dataCy?: string;
  value?: string;
  hideContinueUntilSelected?: boolean;
}

export const ContinueButton = ({
  errorTranslationId = 'buttons.fillInRequiredFields',
  translationId,
  dataCy,
  value,
  hideContinueUntilSelected,
}: ContinueButtonProps): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const { url } = useRouteMatch();

  if (hideContinueUntilSelected && !value) {
    return null;
  }

  const isDisabled = !value;

  const buttonText = formatMessage({ id: !isDisabled ? translationId : errorTranslationId });

  return (
    <ContinueButtonWrapper
      to={isDisabled ? url : `${url}/${value}`}
      data-testid="continue-link"
      data-cy="continue-link"
    >
      <ContinueButtonStyled type="submit" text={buttonText} variant="primary" disabled={isDisabled} dataCy={dataCy} />
    </ContinueButtonWrapper>
  );
};
