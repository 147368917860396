import { VisitDetailsTitle, VisitDetailsWrapper } from 'components/visit-modal/visit-details/visit-details.styles';

import { AddToCalendar } from 'components/visit-modal/visit-details/add-to-calendar';
import { CheckInBlockDisplay } from './check-in/check-in-block-display';
import { EscortItem } from 'components/visit-modal/visit-details/escort-item';
import { FormattedMessage } from 'react-intl';
import { GuestItems } from 'components/visit-modal/visit-details/guest-items';
import { Visit } from 'store/visits/types';
import { VisitLocationInfo } from 'components/visit-modal/visit-details/visit-location-info';
import { NotifyUponCheckIn } from 'components/visit-modal/visit-details/notify-on-check-in/notify-upon-check-in';
import { CustomFields } from './custom-fields/custom-fields';

interface VisitDetailsProps {
  data: Visit | null;
}

export const VisitDetails = ({ data }: VisitDetailsProps): JSX.Element => {
  const recurrenceData = { frequency: data?.frequency, endDate: data?.end_date, count: data?.count };

  return (
    <VisitDetailsWrapper>
      <VisitDetailsTitle data-cy="visit-details-title">
        <FormattedMessage id="labels.visitDetails" />
      </VisitDetailsTitle>
      <AddToCalendar
        arrivalDate={data?.arrival_date}
        departureDate={data?.departure_date}
        visitStatus={data?.visit_status}
        recurrenceData={recurrenceData}
      />
      <VisitLocationInfo floorName={data?.floor} suiteName={data?.suite} />
      {!!data?.visitors?.length && <GuestItems visitors={data.visitors} specialVisitType={data?.special_visit_type} />}
      <CheckInBlockDisplay data={data} />
      {data?.escort_id && <EscortItem escortId={data.escort_id} />}
      {data?.email_recipients && <NotifyUponCheckIn data={data.email_recipients} />}
      {data?.custom_fields && <CustomFields customFields={data?.custom_fields} customForm={data?.custom_form} />}
    </VisitDetailsWrapper>
  );
};
