import { VisitFormRouteParams } from 'components/visit-form/types';
import { useCountedSteps } from './visit-steps-config/visit-steps-config.hooks';
import { useParams } from 'react-router';

export const useStepNumber = (): number => {
  const availableSteps = useCountedSteps();
  const { step: currentStep } = useParams<VisitFormRouteParams>();

  return availableSteps.indexOf(currentStep) + 1;
};
