import { SelectItems } from 'components/visit-form/shared/types';
import { useBuildingData } from 'store/building/hooks';
import { useEffect } from 'react';
import { useField } from 'formik';
import { useIntl } from 'react-intl';
import startCase from 'lodash.startcase';

export const useSelectionFields = (fieldNamePrefix: string): SelectItems[] | undefined => {
  const { visitTypes } = useBuildingData();
  const { formatMessage } = useIntl();
  const [{ value }, , { setValue }] = useField(`${fieldNamePrefix}.checkInOptions`);
  const isEmptyVisitTypes = visitTypes?.length === 0;
  const oneVisitType = visitTypes?.length === 1;

  useEffect(() => {
    if (isEmptyVisitTypes) {
      return;
    }
    const desiredValue = visitTypes?.[0].code.replace(/-/g, '_').toLowerCase();
    if (oneVisitType && value !== desiredValue) {
      setValue(desiredValue, false);
    }
  }, [value, oneVisitType, visitTypes, setValue, isEmptyVisitTypes]);

  return oneVisitType || isEmptyVisitTypes
    ? []
    : visitTypes?.map(({ name, code }) => ({
        value: code.replace(/-/g, '_').toLowerCase(),
        label: formatMessage({
          id: `labels.visitTypes.${code.replace(/-/g, '_')}`,
          defaultMessage: startCase(name?.toLowerCase()),
        }),
      }));
};
