import { FeatureEnablementFlagEnum, UiMetadata } from 'store/ui-metadata/types';
import { ObjectSchema, StringSchema, TestContext, array, date, object, string, ref } from 'yup';

import { ObjectShape } from 'yup/lib/object';
import { Step, VisitCreationFlow, VisitFormValues } from '../types';
import moment from 'moment';
import { YupTestContext, YupTestContextFrom } from 'shared/types/yup-test-context';
import { Building } from 'store/building/types';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { Moment } from 'moment-timezone';

type validationSchema = (
  uiMetadata: UiMetadata | null,
  building: Building | null,
  timeZone?: string | null,
) => ObjectSchema<ObjectShape>;

function wrapWithUiMetadataValidation(
  validationSchema: StringSchema<string | null | undefined>,
  errorKey?: string,
  uiMetadata?: FeatureEnablementFlagEnum,
) {
  return uiMetadata === FeatureEnablementFlagEnum.ENABLED_REQUIRED
    ? validationSchema.required(errorKey)
    : validationSchema;
}

const testIsRequiredForStandardFlow = (value: string | null | undefined, context: TestContext): boolean => {
  const { from } = context as YupTestContext;
  const formValues = from[from.length - 1] as YupTestContextFrom<VisitFormValues> | undefined;

  if (formValues?.value?.visitCreationFlow === VisitCreationFlow.STANDARD) {
    return !!value;
  }

  return true;
};

const testVisitorPhoneIsRequired =
  (visitorPhone?: FeatureEnablementFlagEnum) =>
  (value: string | null | undefined, context: TestContext): boolean => {
    if (visitorPhone === FeatureEnablementFlagEnum.ENABLED_REQUIRED) {
      return testIsRequiredForStandardFlow(value, context);
    }
    return true;
  };

const testVisitorEmailIsRequired =
  (visitorEmail?: FeatureEnablementFlagEnum) =>
  (value: string | null | undefined, context: TestContext): boolean => {
    if (visitorEmail === FeatureEnablementFlagEnum.ENABLED_REQUIRED) {
      return testIsRequiredForStandardFlow(value, context);
    }
    return true;
  };

const testVisitorEmailOrPhoneIsRequired =
  (emailOrPhoneCase?: boolean) =>
  (_value: string | null | undefined, context: TestContext): boolean => {
    if (emailOrPhoneCase) {
      const { phone, email } = context.parent;
      return testIsRequiredForStandardFlow(phone, context) || testIsRequiredForStandardFlow(email, context);
    }
    return true;
  };

const testVisitorCheckInOptionsIsRequired =
  (building: Building | null, uiMetadata?: FeatureEnablementFlagEnum) =>
  (value: string | null | undefined, context: TestContext): boolean => {
    if (uiMetadata === FeatureEnablementFlagEnum.ENABLED_REQUIRED && building?.visit_types?.length) {
      return testIsRequiredForStandardFlow(value, context);
    }

    return true;
  };

const convertToBuildingTimezone = (dateMoment: Moment, timeZone: string): Moment => {
  const localUTCOffset = moment().utcOffset();
  const buildingUTCOffset = moment().tz(timeZone).utcOffset();
  const offset = buildingUTCOffset - localUTCOffset;
  return dateMoment.clone().add(offset, 'minutes');
};

const testTimeStartDate =
  (timeZone: string) =>
  (value: string | null | undefined, { parent }: TestContext): boolean => {
    if (!value || moment(parent.visitDate).isAfter(moment().tz(timeZone), 'date')) {
      return true;
    }
    const currentTimeAtBuilding = convertToBuildingTimezone(moment(), timeZone);
    return moment(value, 'HH:mm A').isSameOrAfter(currentTimeAtBuilding, 'minutes');
  };

function testStartDate(value: string | null | undefined, { parent }: TestContext): boolean {
  if (!value || !parent.endTime) {
    return true;
  }

  return moment(value, 'HH:mm A').isSameOrBefore(moment(parent.endTime, 'HH:mm A'));
}

function testEndDate(value: string | null | undefined, { parent }: TestContext): boolean {
  if (!value || !parent.startTime) {
    return true;
  }

  return moment(value, 'HH:mm A').isAfter(moment(parent.startTime, 'HH:mm A'));
}

const getPhoneValidator = (
  visitorPhone?: FeatureEnablementFlagEnum,
  emailOrPhoneCase?: boolean,
): Record<string, StringSchema> => {
  return {
    phone: string()
      .test('is-required', 'errors.emailOrPhoneRequired', testVisitorEmailOrPhoneIsRequired(emailOrPhoneCase))
      .test('is-required', 'errors.phoneRequired', testVisitorPhoneIsRequired(visitorPhone))
      .test(
        'is-valid-phone',
        'errors.pleaseEnterAValidPhoneNumber',
        (value?: string) => !value || isValidPhoneNumber(value),
      ),
  };
};

const getEmailValidator = (
  visitorEmail?: FeatureEnablementFlagEnum,
  emailOrPhoneCase?: boolean,
): Record<string, StringSchema> => {
  return {
    email: string()
      .test('is-required', 'errors.emailOrPhoneRequired', testVisitorEmailOrPhoneIsRequired(emailOrPhoneCase))
      .test('is-required', 'errors.emailRequired', testVisitorEmailIsRequired(visitorEmail))
      .email('errors.pleaseEnterAValidEmail'),
  };
};

export const validationSchemaBuilders: Record<Step, validationSchema> = {
  [Step.Flow]: () =>
    object().shape({
      visitCreationFlow: string().oneOf([VisitCreationFlow.STANDARD, VisitCreationFlow.CONTACT_WAIVER]).required(),
    }),

  [Step.Visitor]: ((uiMetadata, building) => {
    const visitorPhone = uiMetadata?.ui_metadata.visitor_phone;
    const visitorEmail = uiMetadata?.ui_metadata.visitor_email;
    const emailOrPhoneCase = uiMetadata?.ui_metadata.email_or_phone_case;
    const instructions = uiMetadata?.ui_metadata.checkin_instructions;
    const visitReason = uiMetadata?.ui_metadata.visit_reason;

    return object().shape({
      visitors: array()
        .of(
          object().shape({
            firstName: string().required('errors.pleaseEnterAValidFirstName'),
            lastName: string().required('errors.pleaseEnterAValidLastName'),
            ...getEmailValidator(visitorEmail, emailOrPhoneCase),
            ...getPhoneValidator(visitorPhone, emailOrPhoneCase),
            checkInInstructions: wrapWithUiMetadataValidation(
              string().nullable(),
              'errors.pleaseEnterAValidText',
              instructions,
            ),
            checkInOptions: string().test(
              'is-required',
              'errors.checkInOptionsRequired',
              testVisitorCheckInOptionsIsRequired(building, visitReason),
            ),
          }),
        )
        .required(),
    });
  }) as validationSchema,

  [Step.Escort]: () =>
    object().shape({
      escort: object().shape({
        id: string().nullable(),
        instructions: string(),
      }),
    }),

  [Step.Host]: ((uiMetadata) => {
    const floor = uiMetadata?.ui_metadata.floor;
    const suite = uiMetadata?.ui_metadata.suite;

    return object().shape({
      host: object()
        .shape({
          fullName: string(),
          buildingId: string(),
          floor: wrapWithUiMetadataValidation(string().nullable(), 'errors.floorIsRequired', floor),
          suite: wrapWithUiMetadataValidation(string().nullable(), 'errors.suiteIsRequired', suite),
        })
        .required(),
    });
  }) as validationSchema,

  [Step.Date]: (uiMetadata, _building, timeZone) => {
    const visitStartTime = uiMetadata?.ui_metadata.visit_start_time;
    const visitEndTime = uiMetadata?.ui_metadata.visit_end_time;

    return object().shape({
      date: object()
        .shape({
          visitDate: date().min(moment().startOf('day').toDate(), 'errors.visitDateShouldBeInFuture'),
          visitEndDate: date().min(ref('visitDate'), 'errors.endDateShouldBeAfterVisitDate').nullable(),
          startTime: wrapWithUiMetadataValidation(
            string()
              .nullable()
              .test('is-in-future', 'errors.startTimeShouldBeInFuture', testTimeStartDate(timeZone as string))
              .test('is-before', 'errors.startTimeShouldBeBeforeEnd', testStartDate),
            'errors.startTimeIsRequired',
            visitStartTime,
          ),
          endTime: wrapWithUiMetadataValidation(
            string().nullable().test('is-after', 'errors.endTimeShouldBeAfterStart', testEndDate),
            'errors.endTimeIsRequired',
            visitEndTime,
          ),
          endDate: date().min(ref('visitDate'), 'errors.recurrenceEndShouldBeAfterVisit').nullable(),
        })
        .required(),
    });
  },

  [Step.Custom_Fields]: () => object().shape({}),
};
