import styled from 'styled-components';
import React from 'react';

interface Props {
  size?: number;
  lineWidth?: number;
  indent?: number;
  orientation?: 'horizontal' | 'vertical';
}

const Line = styled.div<Props>`
  background-color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
  opacity: 0.5;
  bottom: 0;
  width: 100%;
  height: 1px;
`;

export interface TextBreakProps {
  children: React.ReactNode;
}

const StyledTextBreak = styled.div`
  display: flex;
  align-items: center;
  margin: 24px 0;
`;

const Text = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.fontColor};
  font-style: italic;
  margin: 0 22px;
`;

export const LineDivider: React.FC<TextBreakProps> = ({ children }: TextBreakProps): JSX.Element => (
  <StyledTextBreak>
    <Line className="line-divider" data-testid="line" />
    <Text>{children}</Text>
    <Line className="line-divider" data-testid="line" />
  </StyledTextBreak>
);
