import { Option } from '../../choose-screen-form-data';
import { useIntl } from 'react-intl';
import { useField } from 'formik';
import { SelectButtonStyle } from './choose-buttons.styles';
import { SelectButton } from '@hqo/react-components-library/dist/atoms/selectButton';

interface ChooseScreenFormProps {
  fieldName: string;
  options: Option[];
}

export const ChooseButtons = ({ fieldName, options }: ChooseScreenFormProps): JSX.Element => {
  const { formatMessage } = useIntl();
  const [{ value, onChange }] = useField(fieldName);

  return (
    <>
      {options.map((item) => (
        <SelectButtonStyle key={item.value}>
          <SelectButton
            value={item.value}
            name={fieldName}
            variant="outlined"
            checked={item.value === value}
            text={formatMessage({ id: item.label })}
            onChange={onChange}
            testId={fieldName}
            sizeOption="md"
          />
        </SelectButtonStyle>
      ))}
    </>
  );
};
