import { useDispatch } from 'react-redux';

import { VisitParams } from 'shared/types';
import { cancelVisit } from 'store/visits/actions';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { useIntl } from 'react-intl';
import { BUTTON_VARIANT } from '@hqo/react-components-library';

interface CancelVisit {
  handleCancel: VoidFunction;
}

export const useCancelVisit = (): CancelVisit => {
  const { ownerId, visitId } = useParams<VisitParams>();
  const dispatch = useDispatch();
  const handleCancel = useCallback(() => {
    dispatch(cancelVisit.request({ buildingId: ownerId, visitId }));
  }, [dispatch, ownerId, visitId]);

  return { handleCancel };
};

export const useCancelButtons = (handleCancel: () => void, handleClose: () => void) => {
  const { formatMessage } = useIntl();

  const buttons = useMemo(
    () => [
      {
        handler: handleCancel,
        title: formatMessage({ id: 'labels.cancelVisit.yesButton' }),
        dataCy: 'yes-button',
      },
      {
        handler: handleClose,
        title: formatMessage({ id: 'labels.cancelVisit.noButton' }),
        variant: BUTTON_VARIANT.SECONDARY,
        dataCy: 'no-button',
      },
    ],
    [handleCancel, handleClose, formatMessage],
  );

  return buttons;
};
