import { useState, useCallback, ChangeEvent, FocusEvent, KeyboardEvent } from 'react';
import { useField } from 'formik';
import { string, ValidationError } from 'yup';
import { useIntl } from 'react-intl';
import { ChipItem } from '@hqo/react-components-library/dist/atoms/form-field';

const SPACE_DELIMETER = ' ';
const EMAIL_DELIMETERS = [',', SPACE_DELIMETER];
const EMAIL_DELIMETER_REGEX = new RegExp(`[${EMAIL_DELIMETERS.join('')}]+`);

const emailValidatorRule = string().email('errors.pleaseEnterAValidEmail');

const useEmailValidation = () => {
  const intl = useIntl();
  const [error, setError] = useState('');

  const validateEmail = useCallback(
    (email: string) => {
      try {
        emailValidatorRule.validateSync(email);
        return false;
      } catch (validationError) {
        setError(intl.formatMessage({ id: (validationError as ValidationError).errors[0] }));
        return true;
      }
    },
    [intl],
  );

  return { error, validateEmail };
};

const useEmailInputChange = (
  onChange: (email: string) => void,
  addNewEmail: (email: string) => void,
  setShowError: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const handleChange = useCallback(
    ({ target: { value: targetValue } }: ChangeEvent<{ value: string }>) => {
      const emailInputParts = targetValue.split(EMAIL_DELIMETER_REGEX);
      const [email] = emailInputParts;
      const isDelimiterAdded = emailInputParts.length > 1;

      if (isDelimiterAdded) {
        addNewEmail(email);
        return;
      }

      setShowError(false);
      onChange(email);
    },
    [addNewEmail, onChange, setShowError],
  );

  return handleChange;
};

const useEmailInput = (chips: string[], setChips: (value: string[]) => void) => {
  const { error, validateEmail } = useEmailValidation();
  const [value, onChange] = useState('');
  const [showError, setShowError] = useState(false);

  const addNewEmail = useCallback(
    (email: string) => {
      if (!email) {
        return;
      }

      const isErrored = validateEmail(email);

      if (isErrored) {
        setShowError(true);
        onChange(email);
      } else {
        setChips([...chips, email]);
        onChange('');
      }
    },
    [chips, setChips, validateEmail],
  );

  const handleChange = useEmailInputChange(onChange, addNewEmail, setShowError);

  return { value, handleChange, error, showError, addNewEmail };
};

export const useMultiEmail = (fieldNamePrefix: string) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ value: chips }, _, { setValue: setChips }] = useField<string[]>(`${fieldNamePrefix}.emailRecipients`);
  const { value, handleChange, error, showError, addNewEmail } = useEmailInput(chips, setChips);

  const handleAddNewEmail = useCallback(
    ({ target: { value: targetValue } }: FocusEvent<HTMLInputElement>) => addNewEmail(targetValue),
    [addNewEmail],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent<HTMLInputElement>) => {
      if (event.key === SPACE_DELIMETER) {
        event.preventDefault();
        addNewEmail(event.currentTarget.value);
      }
    },
    [addNewEmail],
  );

  const handleRemove = useCallback(
    (chipToRemove: ChipItem) => {
      setChips(chips.filter((email) => email !== chipToRemove.value));
    },
    [chips, setChips],
  );

  return { value, chips, error, showError, handleChange, handleRemove, handleAddNewEmail, handleKeyDown };
};
