import { FieldWrapper } from 'components/visit-form/shared/field-wrapper';
import { InputProps } from '@hqo/react-components-library/dist/atoms/form-field';
import { StyledInput } from './form-input.styles';
import { Visit } from 'store/visits/types';
import { VisitorRegistrationFlagProperties } from 'store/ui-metadata/types';
import { useField } from 'formik';
import { useFieldTranslations } from 'hooks/use-field-traslations.hooks';
import { useUIMetadataFieldRequirements } from 'components/visit-form/shared/form.hooks';

interface FormInputProps extends InputProps {
  fieldName: string;
  translationKey: string;
  uiMetadataKey?: keyof VisitorRegistrationFlagProperties;
  editFieldKey?: keyof Visit;
}

export const FormInput = ({
  fieldName,
  uiMetadataKey,
  translationKey,
  editFieldKey,
  ...otherProps
}: FormInputProps): JSX.Element | null => {
  const [field, { error, touched }] = useField({ name: fieldName });
  const { label, placeholder } = useFieldTranslations(translationKey);
  const { hidden, required } = useUIMetadataFieldRequirements(uiMetadataKey, editFieldKey);
  const showError = touched && !!error;
  return hidden ? null : (
    <FieldWrapper touched={touched} error={error}>
      <StyledInput
        label={label}
        placeholder={placeholder}
        name={fieldName}
        variant={showError ? 'error' : 'master'}
        isErrored={showError}
        required={otherProps.required || required}
        value={field.value}
        onChange={field.onChange}
        onBlur={field.onBlur}
        dataCy={translationKey}
        {...otherProps}
      />
    </FieldWrapper>
  );
};
