import { useSelector } from 'react-redux';
import { buildingState } from 'store/building/selectors';
import { StepWrapper } from 'components/visit-form/shared/step-wrapper';
import { HostStepFields } from 'components/visit-form/steps/host-step/host-step-fields';
import { FormLoader } from 'components/visit-form/shared/visit-form.styles';
import { SearchNameField } from 'components/visit-form/shared/search-name-field';
import { useHostsState } from 'store/hosts/hooks';

export const AltHostStep = (): JSX.Element => {
  const { data, loading } = useSelector(buildingState);
  useHostsState();

  if (loading) {
    return <FormLoader />;
  }

  return (
    <StepWrapper titleTranslationId="labels.hostVisit.alt.title">
      <SearchNameField fieldName="host.hostName" translationKey="hostName" />
      <HostStepFields building={data} />
    </StepWrapper>
  );
};
