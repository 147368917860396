import { useSelectionFields } from 'components/visit-form/steps/visitor-step/visitor-form/visitor-form.hook';
import { SelectField } from 'components/visit-form/steps/visitor-step/select-field/select-field';
import { VisitCreationFlow, VisitFormValues } from 'components/visit-form/types';
import { useFormikContext } from 'formik';

interface CheckInOptionsProps {
  fieldNamePrefix: string;
}
export const CheckInOptions = ({ fieldNamePrefix }: CheckInOptionsProps): JSX.Element | null => {
  const checkInOptionFields = useSelectionFields(fieldNamePrefix);
  const {
    values: { visitCreationFlow },
  } = useFormikContext<VisitFormValues>();

  if (visitCreationFlow === VisitCreationFlow.STANDARD && !!checkInOptionFields?.length) {
    return (
      <SelectField
        fieldNamePrefix={fieldNamePrefix}
        fieldName="checkInOptions"
        options={checkInOptionFields}
        uiMetadataKey="visit_reason"
      />
    );
  }

  return null;
};
