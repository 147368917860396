import { CircularArrows } from '@hqo/react-components-library';
import styled from 'styled-components';

export const VisitDatePanel = styled.div`
  display: flex;
  white-space: nowrap;
  flex-direction: column;
  margin-right: 2em;
  flex-grow: 0;
  min-width: 96px;
`;

export const ArrivalDate = styled.span`
  font-size: 18px;
  font-weight: bold;
`;

export const ArrivalTime = styled.span`
  font-style: italic;
  color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
  font-size: 14px;
`;

export const CircularArrowsIcon = styled(CircularArrows)`
  color: ${({ theme }) => theme.colors.fontColor};
`;

export const RecurrenceWrapper = styled.div`
  margin-top: 8px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
`;
