import { faExclamationTriangle, faSearch } from '@fortawesome/pro-regular-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-duotone-svg-icons';

import { App } from 'components/root/app';
import { BrowserRouter } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { DeviceProvider } from '@hqo/react-components-library/dist/device';
import { HqoThemeProvider } from '@hqo/react-components-library/dist/hqo-theme-provider';
import { IntlProvider } from 'components/root/intl-provider';
import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { RootStyles } from './root.styles';
import { history } from 'store/router/history';
import { library } from '@hqo/react-components-library/dist/tenant-web/elements/theme/icons/svgCore';
import { store } from 'store';
import { ErrorBoundary, init as initSentry } from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { ErrorPage } from 'pages/error';
import { MiniappSdkProvider } from 'components/miniapp-sdk-provider';

library.add(faExclamationTriangle, faSearch, faCircleXmark);

initSentry({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_VERSION,
  autoSessionTracking: true,
  environment: process.env.NODE_ENV,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  normalizeDepth: 10,
});

export const Root: React.FC = (): JSX.Element => (
  <ReduxProvider store={store}>
    <MiniappSdkProvider>
      <HqoThemeProvider>
        <BrowserRouter>
          <ConnectedRouter history={history}>
            <IntlProvider>
              <ErrorBoundary fallback={<ErrorPage />}>
                <DeviceProvider>
                  <RootStyles />
                  <App />
                </DeviceProvider>
              </ErrorBoundary>
            </IntlProvider>
          </ConnectedRouter>
        </BrowserRouter>
      </HqoThemeProvider>
    </MiniappSdkProvider>
  </ReduxProvider>
);
