import styled, { css } from 'styled-components';

import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { IconCircleWrapper } from 'components/shared/icon-circle-wrapper';
import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-self: stretch;
  width: 100%;

  padding-top: 50px;

  max-width: 328px;

  color: ${({ theme }) => theme.colors.fontColor};
  text-align: center;

  ${mediaMaxWidth(css`
    max-width: 100%;
  `)}
`;

export const Confirmation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin: auto 0;
  padding: 40px;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 12px;
`;

export const IconWrapper = styled(IconCircleWrapper)`
  color: ${({ theme }) => theme.colors.admin.pink};
  margin-bottom: 16px;
`;

export const YesButton = styled(Button)`
  padding: 6px 22px;

  ${mediaMaxWidth(css`
    padding: 12px 22px;
  `)}
`;

export const NoButton = styled(YesButton)`
  padding: 6px 22px;
  border-color: ${({ theme }) => theme.colors.greys.darkGrey6};
  color: ${({ theme }) => theme.colors.greys.darkGrey6};
`;

export const ConfirmationTitle = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin: 0;
`;

export const ConfirmationDescription = styled.p`
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 0;
  margin-top: 36px;
`;
