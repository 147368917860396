import { DATE_FIELDS, GUEST_FIELDS } from 'shared/consts/edit-fields';

import { Edit as EditIcon } from '@hqo/react-components-library/dist/icons';
import { Link } from 'react-router-dom';
import React from 'react';
import { Step } from 'components/visit-form/types';
import { Visit } from 'store/visits/types';
import { useFieldMetadata } from 'store/ui-metadata/hooks';
import { useRouteMatch } from 'react-router';

interface EditingLinkProps {
  step: Step;
}
const stepFieldsMap = new Map<Step, string[]>([
  [Step.Visitor, GUEST_FIELDS],
  [Step.Date, DATE_FIELDS],
]);

const isEditingDisabled = (
  isEditingEnabled: boolean | null,
  editableFields: (keyof Visit)[] | null,
  step: Step,
): boolean => {
  const editableFieldsSet = new Set(editableFields ?? []);
  const stepFields = stepFieldsMap.get(step) ?? [];
  const hasEditableFields = !editableFields || stepFields.some((field) => editableFieldsSet.has(field as keyof Visit));
  return !isEditingEnabled || !hasEditableFields;
};

export const EditingLink: React.FC<EditingLinkProps> = ({ step }: EditingLinkProps): JSX.Element | null => {
  const { url } = useRouteMatch();
  const isEditingEnabled = useFieldMetadata('visit_editing_enabled');
  const editableFields = useFieldMetadata('editable_fields');
  const testId = `${step}-editing-link`;
  if (isEditingDisabled(isEditingEnabled, editableFields, step)) {
    return null;
  }
  return (
    <Link to={`${url}/edit/${step}`} data-testid={testId} data-cy={testId}>
      <EditIcon />
    </Link>
  );
};
