import { LineDivider } from 'components/visit-form/steps/visitor-step/visitor-form/visitor-contact-options/line';
import { Asterisk } from '@hqo/react-components-library';
import { FormInput } from 'components/visit-form/shared/form-input';

import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { useUiMetadata } from 'store/ui-metadata/hooks';
import { VisitCreationFlow } from 'components/visit-form/types';
import { InternationalPhoneInput } from 'components/visit-form/steps/visitor-step/visitor-form/international-phone-input';

interface ComponentProps {
  fieldNamePrefix: string;
  visitCreationFlow: VisitCreationFlow | null;
}

const StyledLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: ${({ theme }) => theme.colors.fontColor};
  margin-bottom: 12px;
`;

export const VisitorContactEmailOrPhone = ({
  fieldNamePrefix,
  visitCreationFlow,
}: ComponentProps): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const metadata = useUiMetadata();

  if (metadata?.ui_metadata.email_or_phone_case && visitCreationFlow === VisitCreationFlow.STANDARD) {
    return (
      <>
        <StyledLabel>
          {formatMessage({ id: 'labels.emailOrPhone' })}
          <Asterisk />
        </StyledLabel>
        <FormInput fieldName={`${fieldNamePrefix}.email`} translationKey="email" disabled={false} />
        <LineDivider>{formatMessage({ id: 'labels.or' })}</LineDivider>
        <InternationalPhoneInput fieldNamePrefix={`${fieldNamePrefix}`} />
      </>
    );
  }
  return null;
};
