import { Route, Switch, useRouteMatch } from 'react-router';

import { DateStep } from 'components/visit-form/steps/date-step';
import { EscortStep } from 'components/visit-form/steps/escort-step';
import { FlowStep } from 'components/visit-form/steps/flow-step';
import { HostStep } from 'components/visit-form/steps/host-step';
import { CustomFormStep } from 'components/visit-form/steps/custom-form-step';
import { Step } from '../types';
import { VisitorStep } from 'components/visit-form/steps/visitor-step';
import { useIsEditMode } from 'components/visit-form/context/is-editing.hook';
import { useValidatePreviousSteps } from './hooks/visit-form-root.hooks';

export const VisitFormRouteSteps = (): JSX.Element => {
  const isEditingMode = useIsEditMode();
  const { path } = useRouteMatch();
  useValidatePreviousSteps(isEditingMode);

  return (
    <Switch>
      <Route path={`${path}(${Step.Visitor})`}>
        <VisitorStep isEditMode={isEditingMode} />
      </Route>
      <Route path={`${path}(${Step.Escort})`}>
        <EscortStep isEditMode={isEditingMode} />
      </Route>
      <Route path={`${path}(${Step.Date})`}>
        <DateStep isEditMode={isEditingMode} />
      </Route>
      <Route path={`${path}(${Step.Custom_Fields})`}>
        <CustomFormStep isEditMode={isEditingMode} />
      </Route>
      {!isEditingMode && (
        <>
          <Route path={`${path}(${Step.Flow})`} component={FlowStep} />
          <Route path={`${path}(${Step.Host})`} component={HostStep} />
        </>
      )}
    </Switch>
  );
};
