import { useMemo } from 'react';
import { VisitorFormValues } from 'components/visit-form/types';

export const useCollapsedVisitors = (visitorsValue: VisitorFormValues[], currentIndex: number): VisitorFormValues[] =>
  useMemo(() => {
    const visitorsList = [...visitorsValue];
    visitorsList.splice(currentIndex, 1);

    return visitorsList;
  }, [visitorsValue, currentIndex]);
