import styled, { css } from 'styled-components';

import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';

export const StepContainer = styled.div`
  max-width: 340px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  ${mediaMaxWidth(css`
    width: 100%;
    max-width: 100%;
  `)}
`;
