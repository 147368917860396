import styled from 'styled-components';
import { Close } from '@hqo/react-components-library/dist/icons';

export const VisitorsListStyles = styled.ul`
  padding: 0;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column-reverse;
`;

export const VisitorItemWrapper = styled.li`
  display: flex;
  align-items: center;
  list-style-type: none;
  padding: 20px 0;
  min-height: 80px;
  border-bottom: 1px solid ${({ theme }): string => theme.colors.greys.sharedMediumGrey};
`;

export const VisitorItemContent = styled.div`
  width: 100%;
`;

export const VisitorFullName = styled.div`
  line-height: 20px;
  font-size: 20px;
  color: ${({ theme }): string => theme.colors.fontColor};
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const VisitorInfo = styled.div`
  line-height: 16px;
  margin-top: 8px;
  font-size: 14px;
  color: ${({ theme }): string => theme.colors.greys.sharedDarkGrey};

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const CloseButtonStyles = styled(Close)`
  cursor: pointer;
`;
