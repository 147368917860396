import { FeatureEnablementFlagEnum, VisitorRegistrationFlagProperties } from 'store/ui-metadata/types';

import { Visit } from 'store/visits/types';
import { useFieldMetadata } from 'store/ui-metadata/hooks';
import { useIsEditMode } from 'components/visit-form/context/is-editing.hook';

type UIFieldRequirements = { hidden: boolean; required: boolean };
const getShowEdit = (editMode: boolean, editFieldArray: (keyof Visit)[] | null, fieldName?: keyof Visit) => {
  return (
    !editMode ||
    editFieldArray == null ||
    (Array.isArray(editFieldArray) && fieldName && editFieldArray.includes(fieldName))
  );
};

export function useUIMetadataFieldRequirements(
  fieldName?: keyof VisitorRegistrationFlagProperties,
  visitFieldName?: keyof Visit,
): UIFieldRequirements {
  const fieldFlag = useFieldMetadata(fieldName);
  const isEditingMode = useIsEditMode();
  const editFieldArray = useFieldMetadata('editable_fields');
  const showEdit = getShowEdit(isEditingMode, editFieldArray, visitFieldName ?? (fieldName as keyof Visit));

  return {
    hidden: fieldFlag === FeatureEnablementFlagEnum.DISABLED || !showEdit,
    required: fieldFlag !== FeatureEnablementFlagEnum.ENABLED_OPTIONAL && !!fieldFlag,
  };
}
