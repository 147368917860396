import { useBuildingData } from 'store/building/hooks';
import { useIntl } from 'react-intl';
import startCase from 'lodash.startcase';

export const useCheckInOption = (id?: number | null): string | undefined => {
  const { formatMessage } = useIntl();
  const { visitTypes } = useBuildingData();
  const selectedVisitType = visitTypes?.find((visitType) => visitType.id === id);
  const selectedVisitTypeCode = selectedVisitType?.code.replace(/-/g, '_');

  return (
    selectedVisitTypeCode &&
    formatMessage({
      id: `labels.visitTypes.${selectedVisitTypeCode}`,
      defaultMessage: startCase(selectedVisitType?.name?.toLowerCase()),
    })
  );
};
