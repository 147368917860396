import { Buttons } from 'components/shared/confirmation-modal/buttons';
import { Confirmation } from 'components/shared/confirmation-modal/confirmation';
import { Loader } from 'components/shared/loader';
import { Wrapper } from './confirmation-modal.styles';

interface ConfirmationModalProps {
  titleTranslationId: string;
  descriptionTranslationId?: string;
  yesButtonTranslationId: string;
  noButtonTranslationId: string;
  onYesClick?: VoidFunction;
  onNoClick?: VoidFunction;
  loading?: boolean;
  dataCy?: string;
  dataTestId?: string;
}

export const ConfirmationModal = ({
  titleTranslationId,
  descriptionTranslationId,
  noButtonTranslationId,
  yesButtonTranslationId,
  onNoClick: handleNoClick,
  onYesClick: handleYesClick,
  loading,
  dataCy,
  dataTestId,
}: ConfirmationModalProps): JSX.Element => {
  if (loading) {
    return <Loader />;
  }

  return (
    <Wrapper data-cy={dataCy} data-testid={dataTestId}>
      <Confirmation titleTranslationId={titleTranslationId} descriptionTranslationId={descriptionTranslationId} />
      <Buttons
        noButtonTranslationId={noButtonTranslationId}
        yesButtonTranslationId={yesButtonTranslationId}
        onNoClick={handleNoClick}
        onYesClick={handleYesClick}
      />
    </Wrapper>
  );
};
