import { VisitFormRouteParams } from 'components/visit-form/types';
import { useCountedSteps } from 'components/visit-form/visit-steps-config/visit-steps-config.hooks';
import { useParams } from 'react-router';

interface FormProgressInfo {
  stepNumber: number;
  stepsCount: number;
  percentage: number;
}

export const useFormProgress = (): FormProgressInfo => {
  const availableSteps = useCountedSteps();
  const { step } = useParams<VisitFormRouteParams>();
  const stepNumber = availableSteps.indexOf(step) + 1;
  const stepsCount = availableSteps.length;

  return {
    stepNumber,
    stepsCount,
    percentage: Math.round((stepNumber / stepsCount) * 100),
  };
};
