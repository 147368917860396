import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { SwitchWrapper, SwitchStyled } from './recurring-data.styles';

interface SwitchProps {
  checked: boolean;
  setChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Switch = ({ checked, setChecked }: SwitchProps) => {
  const intl = useIntl();
  const handleChange = useCallback(() => {
    setChecked(!checked);
  }, [checked, setChecked]);

  return (
    <SwitchWrapper>
      <SwitchStyled data-testid="recurring-switch" checked={checked} onChange={handleChange} />
      {intl.formatMessage({ id: 'labels.createRecurringVisit' })}
    </SwitchWrapper>
  );
};
