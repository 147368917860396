import styled from 'styled-components';

export const SelectButtonsWrapper = styled.div`
  display: flex;
  overflow-y: auto;
  border-color: ${({ theme }) => theme.colorsRgba.primary_1};
  .select-button-label {
    font-size: 16px;
    padding: 8px 16px;
  }
  input:focus,
  input:checked {
    & + .select-button-label {
      color: ${({ theme }) => theme.colors.primary};
      border-color: ${({ theme }) => theme.colors.primary};
      background: ${({ theme }) => theme.colorsRgba.primary_1};
      font-weight: bold;
      border-width: 2px;
    }
  }
`;

export const FieldLabelSpan = styled.span`
  display: block;
  font-size: 12px;
  font-style: italic;
`;
