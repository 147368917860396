import {
  HostType,
  VisitCreationFlow,
  VisitDateFormValues,
  VisitEscortFormValues,
  VisitFormValues,
  VisitHostFormValues,
  VisitorFormValues,
} from 'components/visit-form/types';
import { Visit, Visitor } from 'store/visits/types';

import { User } from 'store/user/types';
import { VisitTypeData } from 'store/building/types';
import { useBuildingData } from 'store/building/hooks';
import { useCurrentUser } from 'hooks/use-current-user.hook';
import { useFieldMetadata } from 'store/ui-metadata/hooks';
import { useIsFlowStepAvailable } from 'components/visit-form/visit-steps-config/visit-steps-config.hooks';
import { useMemo } from 'react';
import { useTimeZone } from 'shared/hooks';

export const visitorInitialValue: VisitorFormValues = {
  id: null,
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  checkInOptions: '',
  checkInInstructions: '',
  reasonForVisit: '',
  isPremier: false,
  emailRecipients: [],
};

const visitDateInitialValues: VisitDateFormValues = {
  visitDate: null,
  visitEndDate: null,
  startTime: null,
  endTime: null,
  frequency: null,
  endDate: null,
  count: null,
};

const visitInitialValues: VisitFormValues = {
  id: null,
  visitCreationFlow: null,
  host: {
    hostName: '',
    floor: null,
    suite: null,
    email: '',
    notificationType: { notification: false, emailMessage: false, textMessage: false },
  },
  visitors: [visitorInitialValue],
  date: visitDateInitialValues,
  escort: {
    id: null,
    instructions: '',
  },
  specialVisitType: null,
  hostType: null,
  visit_type_id: null,
  custom_fields: {},
};

const getInitialDateValues = (visitEndDateInputEnabled: boolean): VisitDateFormValues => {
  const today = new Date();

  if (!visitEndDateInputEnabled) {
    return {
      ...visitDateInitialValues,
      visitDate: today,
    };
  }

  return {
    ...visitDateInitialValues,
    visitDate: today,
    visitEndDate: today,
  };
};

const getInitialValuesForNewVisit = (
  isFlowStepAvailable: boolean,
  hostsEnabled: boolean,
  visitEndDateInputEnabled: boolean,
): VisitFormValues => ({
  ...visitInitialValues,
  date: getInitialDateValues(visitEndDateInputEnabled),
  visitCreationFlow: isFlowStepAvailable ? null : VisitCreationFlow.STANDARD,
  hostType: !hostsEnabled ? HostType.SELF : null,
});

const formatInitialTimeValue = (dateTime: string | Date | undefined, timeZone: string): string | null => {
  return dateTime
    ? new Date(dateTime).toLocaleTimeString(undefined, {
        timeZone,
        timeStyle: 'short',
        hour12: false,
      })
    : null;
};

// eslint-disable-next-line complexity
const getDateStepValues = (
  visit: Visit,
  timeZone: string,
  initialDateValues: VisitDateFormValues,
): VisitDateFormValues => {
  if (!visit.arrival_date && !visit.departure_date) {
    return initialDateValues;
  }

  return {
    visitDate: visit.arrival_date ? new Date(visit.arrival_date) : initialDateValues.visitDate,
    visitEndDate: visit.departure_date ? new Date(visit.departure_date) : initialDateValues.visitEndDate,
    startTime: formatInitialTimeValue(visit.arrival_date, timeZone),
    endTime: formatInitialTimeValue(visit.departure_date, timeZone),
    frequency: visit.frequency ?? initialDateValues.frequency,
    endDate: visit.end_date ?? initialDateValues.endDate,
    count: visit.count ?? initialDateValues.count,
  };
};

const getInitialHostValuesFromVisit = (visit: Visit): VisitHostFormValues => ({
  hostName: `${visit.host.first_name} ${visit.host.last_name}`,
  floor: visit.floor,
  suite: visit.suite,
  email: visit.host.email,
  notificationType: { notification: false, emailMessage: false, textMessage: false },
});

const getCheckInOptions = (visit: Visit, visitTypes: VisitTypeData[] | null): string => {
  const visitType = visitTypes?.find(({ id }) => id === visit.visit_type_id);

  return visitType?.code.toLowerCase() ?? visitorInitialValue.checkInOptions;
};
const mapVisitorField = (
  visitorKey: keyof Visitor,
  initialValueKey: keyof VisitorFormValues,
  visitor: Visitor,
): string => {
  return (visitor[visitorKey] ?? (visitorInitialValue[initialValueKey] || '')) as string;
};
const getInitialVisitorsValuesFromVisit = (visit: Visit, visitTypes: VisitTypeData[] | null): VisitorFormValues[] => {
  const checkInOptions = getCheckInOptions(visit, visitTypes);
  return (
    visit.visitors?.map((visitor) => ({
      checkInOptions,
      firstName: visitor.first_name,
      lastName: visitor.last_name,
      id: mapVisitorField('id', 'id', visitor),
      phone: mapVisitorField('phone', 'phone', visitor),
      email: mapVisitorField('email', 'email', visitor),
      isPremier: visitor.is_premier ?? visitorInitialValue.isPremier,
      checkInInstructions: visit.special_instruction_for_visitor ?? visitorInitialValue.checkInInstructions,
      reasonForVisit: visit.reason_for_visit ?? visitorInitialValue.reasonForVisit,
      emailRecipients: visitor.email_recipients ?? visitorInitialValue.emailRecipients,
    })) ?? []
  );
};

const getInitialEscortValuesFromVisit = (visit: Visit): VisitEscortFormValues => ({
  id: visit.escort_id ?? visitInitialValues.escort.id,
  instructions: visitInitialValues.escort.instructions,
});

// eslint-disable-next-line complexity
const getInitialValuesFromVisit = (
  visit: Visit,
  user: User | null,
  visitTypes: VisitTypeData[] | null,
  timeZone: string,
  visitEndDateInputEnabled: boolean,
): VisitFormValues => ({
  id: visit.id,
  visitCreationFlow: visit.special_visit_type ? VisitCreationFlow.CONTACT_WAIVER : VisitCreationFlow.STANDARD,
  host: getInitialHostValuesFromVisit(visit),
  visitors: getInitialVisitorsValuesFromVisit(visit, visitTypes),
  date: getDateStepValues(visit, timeZone, getInitialDateValues(visitEndDateInputEnabled)),
  escort: getInitialEscortValuesFromVisit(visit),
  specialVisitType: visit.special_visit_type ?? visitInitialValues.specialVisitType,
  hostType: visit.host.email === user?.email ? HostType.SELF : HostType.ALT,
  visit_type_id: visit.visit_type_id ?? visitInitialValues.visit_type_id,
  custom_fields: visit.custom_fields || {},
});

export const useInitialValues = (visit?: Visit | null): VisitFormValues => {
  const hostsEnabled = useFieldMetadata('alt_hosts_enabled');
  const visitEndDateInputEnabled = useFieldMetadata('visit_end_date_input_enabled');
  const isFlowStepAvailable = useIsFlowStepAvailable();
  const [user] = useCurrentUser();
  const timeZone = useTimeZone();
  const { visitTypes } = useBuildingData();

  return useMemo((): VisitFormValues => {
    if (visit) {
      return getInitialValuesFromVisit(visit, user, visitTypes, timeZone, visitEndDateInputEnabled);
    }

    return getInitialValuesForNewVisit(isFlowStepAvailable, !!hostsEnabled, visitEndDateInputEnabled);
  }, [visit, isFlowStepAvailable, hostsEnabled, visitEndDateInputEnabled, user, visitTypes, timeZone]);
};
