import { useState } from 'react';
import { RecurringContent } from './recurring-content';
import { Switch } from './switch';
import { useFieldMetadata } from 'store/ui-metadata/hooks';
import { Wrapper } from './recurring-data.styles';
import { useField } from 'formik';
import { FrequencyEnum } from 'components/visit-form/types';

export const RecurringData = () => {
  const [checked, setChecked] = useState<boolean>(false);
  const fieldFlag = useFieldMetadata('recurrence');
  const [{ value: frequency }] = useField<FrequencyEnum | null>('date.frequency');

  if (!fieldFlag) {
    return null;
  }

  return (
    <Wrapper>
      <Switch checked={checked} setChecked={setChecked} />
      {checked && <RecurringContent frequencyValue={frequency} />}
    </Wrapper>
  );
};
