import styled from 'styled-components';

export const NotifyOnWrapper = styled.div`
  margin-bottom: 32px;
`;

export const NotifyOnTitle = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }): string => theme.colors.fontColor};
`;

export const NotifyOnEmail = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }): string => theme.colors.fontColor};
`;
