/* eslint-disable @typescript-eslint/no-unused-vars */
import { VisitTimeItem } from '../visit-times/visit-times.hooks';
import { RecurrenceEnum } from '../../../types/recurring';
import { FieldWrapperStyled } from 'components/visit-form/shared/field-wrapper/field-wrapper.styles';
import { Picker } from 'components/visit-form/shared/picker';

interface Props {
  items: VisitTimeItem[];
  value: RecurrenceEnum;
  onChange: (value: RecurrenceEnum) => void;
  label: string;
  placeholder: string;
}

export const RecurringSelect = ({ items, value, onChange, label, placeholder }: Props) => {
  return (
    <FieldWrapperStyled>
      <Picker items={items} value={value} onChange={onChange} label={label} placeholder={placeholder} />
    </FieldWrapperStyled>
  );
};
