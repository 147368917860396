import { StyledButton } from 'components/visit-modal/visit-modal.styles';
import { useNavigate } from 'hooks/use-navigate-handler.hook';
import { useIntl } from 'react-intl';
import { ROOT_PATH, VISITS_SUB_PATH } from 'shared/consts';

export const VisitDetailsButton = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const handleClose = useNavigate(`${ROOT_PATH}/${VISITS_SUB_PATH}`);

  return (
    <StyledButton
      variant="cancelButton"
      size="md"
      text={formatMessage({ id: 'button.backToVisits' })}
      dataCy="back-to-visits-button"
      onClick={handleClose}
    />
  );
};
