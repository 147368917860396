import { CheckInBlockTitle, CheckInBlockWrapper, CheckInItem } from './check-in.styles';
import { useIntl } from 'react-intl';

interface CheckInInstructionsProps {
  data?: string;
  translationKey: string;
  datTestId: string;
}

export const CheckInBlock = ({ data, translationKey, datTestId }: CheckInInstructionsProps): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <CheckInBlockWrapper>
      <CheckInBlockTitle>{formatMessage({ id: `labels.checkIn.${translationKey}` })}</CheckInBlockTitle>
      <CheckInItem data-testid={datTestId}>{data}</CheckInItem>
    </CheckInBlockWrapper>
  );
};
