import styled from 'styled-components';

export const GuestFullName = styled.div`
  line-height: 20px;
  font-size: 16px;
  color: ${({ theme }): string => theme.colors.fontColor};
  font-weight: bold;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const GuestInfo = styled.div`
  line-height: 16px;
  font-size: 14px;
  color: ${({ theme }): string => theme.colors.greys.sharedDarkGrey};

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

export const PremierAccess = styled(GuestInfo)`
  padding: 4px 8px;
  font-weight: 500;
  color: ${({ theme }): string => theme.colors.fontColor};
  background-color: ${({ theme }): string => theme.colors.greys.sharedGreyBackground};
  margin-top: 8px;
  margin-right: auto;
`;

export const GuestItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
`;
