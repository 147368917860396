import { useDispatch, useSelector } from 'react-redux';

import { OwnerParams } from 'shared/types';
import { VisitState } from './types';
import { fetchVisit } from 'store/visits/actions';
import { getVisitStateById } from './selectors';
import { prepareStateDataByRootVisit } from './util';
import { useEffect } from 'react';
import { useParams } from 'react-router';

export const useVisitState = (visitId: string, shouldReturnFromCache = false): VisitState => {
  const { ownerId } = useParams<OwnerParams>();
  const dispatch = useDispatch();
  const visitState = useSelector(getVisitStateById(visitId));

  useEffect(() => {
    if (!shouldReturnFromCache) {
      dispatch(fetchVisit.request({ buildingId: ownerId, visitId }));
    }
  }, [shouldReturnFromCache, visitId, ownerId, dispatch]);

  useEffect(() => {
    if (shouldReturnFromCache && !visitState.data && !visitState.loading) {
      dispatch(fetchVisit.request({ buildingId: ownerId, visitId }));
    }
  }, [shouldReturnFromCache, visitState, visitId, ownerId, dispatch]);

  // TODO remove after updating helix response object
  if (!visitState.data?.visitors) {
    return { ...visitState, data: prepareStateDataByRootVisit(visitState.data) };
  }

  return visitState;
};
