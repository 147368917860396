import { VisitForm } from 'components/visit-form';
import React from 'react';
import { useParams } from 'react-router';
import { useVisitState } from 'store/visits/hooks';

export const EditVisitPage: React.FC = () => {
  const { visitId } = useParams<{ visitId: string }>();
  const { loading, data } = useVisitState(visitId, true);

  return <VisitForm visit={data} visitLoading={loading} isEditMode />;
};
