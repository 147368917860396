import { ChooseScreenForm } from 'components/visit-form/shared/choose-screen/choose-screen-form';
import { VisitCreationFlow } from 'components/visit-form/types';
import { BackLinkStyled } from 'components/visit-form/shared/step-wrapper/visit-form-header/visit-form-header.styles';

export const visitCreationFlowOptions = [
  { value: VisitCreationFlow.STANDARD, label: 'labels.visitCreationFlows.standard' },
  { value: VisitCreationFlow.CONTACT_WAIVER, label: 'labels.visitCreationFlows.contactWaiver' },
];

export const FlowSelect = (): JSX.Element => (
  <>
    <BackLinkStyled />
    <ChooseScreenForm
      fieldName="visitCreationFlow"
      titleTranslationId="labels.visitCreationFlowStep.title"
      helperTextTranslationId="labels.visitCreationFlowStep.description"
      buttonTranslationId="buttons.continue"
      dataCy="select-visit-creation-flow-step"
      options={visitCreationFlowOptions}
      hideContinueUntilSelected
    />
  </>
);
