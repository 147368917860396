import styled from 'styled-components';

export const ErrorNotificationWrapper = styled.div`
  margin: 45px 0 0;
  background: ${({ theme }) => theme.colors.universal.white};
  min-width: 100%;
  padding: 16px 16px 32px;
  display: flex;
  justify-content: center;
  border-radius: 0 0 4px 4px;
  border-top: 1px solid ${({ theme }) => theme.colors.greys.sharedMediumGrey};

  .no-search-result {
    .title,
    .description {
      color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
      margin-bottom: 8px;
    }

    .title {
      font-weight: 700;
      font-size: 18px;
      line-height: 22px;
    }

    .button {
      padding: 12px;
    }
  }
`;
