import { useIntl } from 'react-intl';
import { useMultiEmail } from './use-multi-email.hook';
import { useFieldMetadata } from 'store/ui-metadata/hooks';
import { FormFieldStyled } from './visitor-form.style';
import { ChipItem, FormFieldType } from '@hqo/react-components-library/dist/atoms/form-field';

interface MultiEmailProps {
  fieldNamePrefix: string;
}

const prepareChips = (emails: string[]): ChipItem[] => emails.map((email) => ({ value: email, label: email }));

export const MultiEmail = ({ fieldNamePrefix }: MultiEmailProps) => {
  const intl = useIntl();
  const { value, chips, error, showError, handleChange, handleRemove, handleAddNewEmail, handleKeyDown } =
    useMultiEmail(fieldNamePrefix);
  const emailRecipientsEnabled = useFieldMetadata('email_recipients_enabled');

  if (!emailRecipientsEnabled) {
    return null;
  }

  return (
    <FormFieldStyled
      variant="chip-dropdown"
      type="email"
      fieldType={FormFieldType.DYNAMIC_INPUT}
      label={intl.formatMessage({ id: 'fields.emailRecipients.label' })}
      placeholder={intl.formatMessage({ id: 'fields.emailRecipients.placeholder' })}
      value={value}
      chips={prepareChips(chips)}
      onChange={handleChange}
      onChipRemove={handleRemove}
      onBlur={handleAddNewEmail}
      onKeyDown={handleKeyDown}
      isErrored={showError}
      errorText={showError ? error : undefined}
      dataCy="basic-field"
    />
  );
};
