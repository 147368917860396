import { useField } from 'formik';
import { useCallback, useMemo } from 'react';
import { useFieldMetadata } from 'store/ui-metadata/hooks';
import moment, { Moment } from 'moment';

export const useMatchDates = (endDateFieldName = 'visitEndDate') => {
  const visitEndDateInputEnabled = useFieldMetadata('visit_end_date_input_enabled');
  const [{ value }, { touched }, { setValue, setTouched }] = useField<Date | null>(`date.${endDateFieldName}`);
  const handleMatchDates = useCallback(
    (startDate: Moment) => {
      if (moment(startDate).isAfter(value)) {
        setValue(startDate.toDate());
      }

      if (!touched) {
        setTouched(true);
      }
    },
    [setTouched, setValue, touched, value],
  );

  return useMemo(
    () => (visitEndDateInputEnabled ? handleMatchDates : null),
    [handleMatchDates, visitEndDateInputEnabled],
  );
};
