import { Route, Switch, useRouteMatch } from 'react-router';

import { SelectHostTypeStep } from './select-host-type-step';
import { SelfHostStep } from './self-host-step';
import { AltHostStep } from './alt-host-step';
import { HostType } from 'components/visit-form/types';

export const HostStep = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact component={SelectHostTypeStep} />
      <Route path={`${path}/:hostType(${HostType.SELF})`} component={SelfHostStep} />
      <Route path={`${path}/:hostType(${HostType.ALT})`} component={AltHostStep} />
    </Switch>
  );
};
