import React from 'react';
import { useFieldMetadata } from 'store/ui-metadata/hooks';
import { useIntl } from 'react-intl';
import { CancelButton } from './visit-cancel.styles';
import { VisitCancelModal } from './visit-cancel-modal/visit-cancel-modal';
import { useVisitCancelModal } from './visit-cancel.hooks';
import { VisitStatus } from 'store/visits/types';

interface Props {
  arrivalDate: Date;
  visitStatus?: VisitStatus;
}

export const VisitCancel: React.FC<Props> = ({ arrivalDate, visitStatus }: Props): JSX.Element | null => {
  const { formatMessage } = useIntl();
  const cancelVisitsEnabled = useFieldMetadata('cancel_visits_enabled');
  const visitCanceled = visitStatus === VisitStatus.CANCELED;
  const { isVisible, handleCancelClose, handleCancelOpen, handleCancel } = useVisitCancelModal();

  if (!cancelVisitsEnabled || visitCanceled) {
    return null;
  }

  return (
    <>
      <CancelButton data-testid="visit-cancel" data-cy="visit-cancel" onClick={handleCancelOpen}>
        {formatMessage({ id: 'buttons.cancelVisit' })}
      </CancelButton>
      <VisitCancelModal
        isVisible={isVisible}
        onClose={handleCancelClose}
        onCancel={handleCancel}
        arrivalDate={arrivalDate}
      />
    </>
  );
};
