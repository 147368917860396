import styled from 'styled-components';

export const Title = styled.h1`
  display: block;
  font-size: 28px;
  line-height: 32px;
  font-weight: bold;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.fontColor};
`;
