import {
  CloseButtonStyles,
  VisitorFullName,
  VisitorInfo,
  VisitorItemContent,
  VisitorItemWrapper,
} from './visitors-list-item.styles';
import { FormikTouched, useFormikContext } from 'formik';
import { useCallback } from 'react';
import { VisitFormValues, VisitorFormValues } from 'components/visit-form/types';

interface VisitorsListItemProps {
  visitor: VisitorFormValues;
  currentIndex: number;
}

const getFormattedValue = (
  visitors: VisitorFormValues[],
  visitorsTouched: FormikTouched<VisitorFormValues>[] | undefined,
  index: number,
) => {
  const formattedVisitors = [...visitors];
  formattedVisitors.splice(index, 1);
  const formattedTouched = visitorsTouched ? [...visitorsTouched] : [];
  formattedTouched.splice(index, 1);
  return { formattedVisitors, formattedTouched };
};

export const VisitorsListItem = ({ visitor, currentIndex }: VisitorsListItemProps): JSX.Element => {
  const {
    values: { visitors },
    setFieldValue,
    touched: { visitors: visTouched },
    setTouched,
  } = useFormikContext<VisitFormValues>();
  const removeVisitor = useCallback(() => {
    const { formattedVisitors, formattedTouched } = getFormattedValue(visitors, visTouched, currentIndex);
    setFieldValue('visitors', formattedVisitors);
    setTouched({ visitors: formattedTouched });
  }, [visitors, visTouched, currentIndex, setFieldValue, setTouched]);

  return (
    <VisitorItemWrapper>
      <VisitorItemContent>
        <VisitorFullName>{`${visitor?.firstName} ${visitor?.lastName}`}</VisitorFullName>
        {visitor.email && <VisitorInfo>{visitor.email}</VisitorInfo>}
      </VisitorItemContent>
      <CloseButtonStyles
        size="sm"
        variant="primary"
        role="button"
        dataCy="visitor-item-close-icon"
        data-testid="visitor-item-close"
        onClick={removeVisitor}
      />
    </VisitorItemWrapper>
  );
};
