import { TopText, BottomText } from './details-block.styles';

export interface DetailsBlockProps {
  topText?: string;
  bottomText?: string;
  dataCy: string;
}

export const DetailsBlock = ({ topText, bottomText, dataCy }: DetailsBlockProps): JSX.Element => (
  <>
    {topText && <TopText data-cy={`${dataCy}-top-text`}>{topText}</TopText>}
    {bottomText && <BottomText data-cy={`${dataCy}-bottom-text`}>{bottomText}</BottomText>}
  </>
);
