import styled, { css } from 'styled-components';

import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';

export const Wrapper = styled.section`
  max-width: 428px;

  ${mediaMaxWidth(css`
    max-width: 100%;
  `)}
`;
