import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';
import { Loader } from 'components/shared/loader';
import styled, { css } from 'styled-components';

export const FormLoader = styled(Loader)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background-color: ${({ theme }) => theme.colors.universal.white};

  ${mediaMaxWidth(css`
    position: fixed;
  `)}
`;
