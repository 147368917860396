import styled, { css } from 'styled-components';

import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';

export const VisitDetailsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;

  ${mediaMaxWidth(css`
    height: 100%;
    overflow: auto;
    grid-template-rows: min-content;
    grid-template-columns: 1fr;
  `)}
`;

export const VisitModalWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;

  ${mediaMaxWidth(css`
    padding-top: 30px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  `)}
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  ${mediaMaxWidth(css`
    padding-top: 8px;
    background-color: ${({ theme }): string => theme.colors.universal.white};
  `)}
`;

export const StyledButton = styled(Button)`
  padding: 6px 22px;
  border-color: ${({ theme }): string => theme.colors.greys.darkGrey6};
  color: ${({ theme }): string => theme.colors.greys.darkGrey6};

  ${mediaMaxWidth(css`
    width: 100%;
    padding: 12px 22px;
  `)}
`;

export const Divider = styled.div`
  ${mediaMaxWidth(css`
    width: 100%;
    border-bottom: 0.5px solid ${({ theme }): string => theme.colors.greys.sharedMediumGrey};
    margin: 26px 0;
  `)}
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  color: ${({ theme }): string => theme.colors.primary};

  ${mediaMaxWidth(css`
    align-items: center;
    height: 100%;
  `)}
`;
