interface BuildingLocationInfo {
  hasLocationInfo: boolean;
  locationString: string;
}

export const useBuildingLocationInfo = (
  floorName?: string,
  suiteName?: string,
  buildingName?: string,
): BuildingLocationInfo => {
  const locationString = [floorName, suiteName].filter(Boolean).join(', ');
  return {
    hasLocationInfo: !!(buildingName || floorName || suiteName),
    locationString,
  };
};
