import { FieldWrapper } from 'components/visit-form/shared/field-wrapper';
import { VisitTimeItem } from 'components/visit-form/steps/date-step/visit-times/visit-times.hooks';
import { useField } from 'formik';
import { useFieldTranslations } from 'hooks/use-field-traslations.hooks';
import { Picker } from 'components/visit-form/shared/picker';

interface VisitTimeSelectProps {
  fieldName: string;
  items: VisitTimeItem[];
  required: boolean;
}

export const VisitTimeSelect = ({ fieldName, items, required }: VisitTimeSelectProps): JSX.Element => {
  const { label, placeholder } = useFieldTranslations(fieldName);
  const [{ value }, { touched, error }, { setValue }] = useField<string>(`date.${fieldName}`);

  return (
    <FieldWrapper touched={touched} error={error}>
      <Picker
        items={items}
        value={value}
        onChange={setValue}
        label={label}
        placeholder={placeholder}
        required={required}
      />
    </FieldWrapper>
  );
};
