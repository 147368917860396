import { Fragment } from 'react';
import { DynamicFormDataDto } from '@hqo/dynamic-forms-sdk/types/form/form-data.types';
import { useFormAnswers } from './custom-fields.hooks';
import { Answer, Question } from './custom-fields.styles';
import { DynamicFormDto } from '@hqo/dynamic-forms-sdk/types';

interface Props {
  customForm: DynamicFormDto;
  customFields: DynamicFormDataDto;
}

export const CustomFields = ({ customForm, customFields }: Props) => {
  const [formAnswers, error] = useFormAnswers(customForm, customFields);

  if (error) {
    return null;
  }

  return (
    <>
      {formAnswers.map(({ question, answer }) => (
        <Fragment key={question}>
          <Question>{question}</Question>
          <Answer>{answer}</Answer>
        </Fragment>
      ))}
    </>
  );
};
