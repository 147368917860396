import { useCallback } from 'react';
import { useFrameState, useHandleSearch } from './search-name-field.hooks';

import { FormInput } from 'components/visit-form/shared/form-input';
import { SearchFrame } from './search-frame';
import { SearchNameFieldWrapper } from './search-name-field.styles';
import { useField } from 'formik';

interface SearchNameFieldProps {
  fieldName: string;
  translationKey: string;
}

export const SearchNameField = ({ fieldName, translationKey }: SearchNameFieldProps): JSX.Element => {
  const [{ value, onChange }, , { setValue }] = useField(fieldName);
  const { isOpen, showFrame, hideFrame } = useFrameState();
  const selectHostName = useCallback(
    (item) => {
      setValue(item.target.innerText);
      hideFrame();
    },
    [hideFrame, setValue],
  );
  const handleSearch = useHandleSearch(onChange, showFrame);

  return (
    <SearchNameFieldWrapper isActive={isOpen && !!value}>
      <FormInput
        autoComplete="off"
        className="search-name-field"
        fieldName={fieldName}
        value={value ?? ''}
        translationKey={translationKey}
        onChange={handleSearch}
        data-testid={`${translationKey}-input`}
      />
      <SearchFrame value={value} selectHostName={selectHostName} open={isOpen && !!value} onCloseFrame={hideFrame} />
    </SearchNameFieldWrapper>
  );
};
