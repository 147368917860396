import { useDispatch, useSelector } from 'react-redux';

import { HostState } from './types';
import { OwnerIdParam } from 'shared/types';
import { fetchHosts } from 'store/hosts/actions';
import { hostsState as hostsStateSelector } from './selectors';
import { useEffect } from 'react';
import { useParams } from 'react-router';

export const useHostsState = (): HostState => {
  const { ownerId } = useParams<OwnerIdParam>();
  const dispatch = useDispatch();
  const hostsState = useSelector(hostsStateSelector);

  useEffect(() => {
    if (!hostsState.data) {
      dispatch(fetchHosts.request({ ownerId }));
    }
  }, [dispatch, hostsState.data, ownerId]);

  return hostsState;
};
