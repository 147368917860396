import styled, { css } from 'styled-components';

import { BackLink } from './back-link';
import { ProgressBar } from './progress-bar';
import { StepIndicator } from './step-indicator';
import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';

export const HeaderWrapper = styled.header`
  display: grid;
  grid-template-columns: 72px 1fr;
  grid-template-areas: 'back progress' 'step step';
  align-items: center;
  grid-gap: 32px;

  ${mediaMaxWidth(css`
    grid-template-columns: 1fr;
    grid-template-areas: 'progress' 'back' 'step';
    grid-gap: 12px;
  `)}
`;

export const BackLinkStyled = styled(BackLink)`
  grid-area: back;
`;

export const ProgressBarStyled = styled(ProgressBar)`
  grid-area: progress;
  width: 374px;

  ${mediaMaxWidth(css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
  `)}
`;

export const StepIndicatorStyled = styled(StepIndicator)`
  grid-area: step;
`;
