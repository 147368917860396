import { VisitCreationFlow, VisitFormValues } from 'components/visit-form/types';
import { useFormikContext } from 'formik';
import { StepWrapper } from 'components/visit-form/shared/step-wrapper';
import { VisitorForm } from 'components/visit-form/steps/visitor-step/visitor-form';
import { useFieldMetadata } from 'store/ui-metadata/hooks';
import { GroupVisitors } from 'components/visit-form/steps/visitor-step/group-visitors';
import { StepProps } from 'components/visit-form/steps/step.props.interface';

export const VisitorStep = ({ isEditMode }: StepProps): JSX.Element => {
  const {
    values: { visitCreationFlow, visitors },
  } = useFormikContext<VisitFormValues>();
  const openedVisitorIndex: number = visitors.length - 1;

  const isGroupVisitsAvailable = useFieldMetadata('group_visits');
  const isGroupVisitsAvailableForContactWaiver = useFieldMetadata('group_visits_with_contact_waiver');
  const isGroupVisitorsVisible =
    isGroupVisitsAvailable &&
    (visitCreationFlow === VisitCreationFlow.STANDARD || isGroupVisitsAvailableForContactWaiver);

  return (
    <StepWrapper titleTranslationId="labels.createVisit.guest.title" isEditMode={isEditMode}>
      <VisitorForm index={openedVisitorIndex} />
      {isGroupVisitorsVisible && <GroupVisitors currentIndex={openedVisitorIndex} />}
    </StepWrapper>
  );
};
