import { Visit } from 'store/visits/types';
import { VisitTimeItem } from 'components/visit-form/steps/date-step/visit-times/visit-times.hooks';
import { VisitTimeSelect } from './visit-time-select';
import { VisitorRegistrationFlagProperties } from 'store/ui-metadata/types';
import { useUIMetadataFieldRequirements } from 'components/visit-form/shared/form.hooks';

interface VisitTimeProps {
  fieldName: string;
  uiMetadataKey: keyof VisitorRegistrationFlagProperties;
  times: VisitTimeItem[];
  editFieldKey?: keyof Visit;
}

export const VisitTime = ({ fieldName, uiMetadataKey, times, editFieldKey }: VisitTimeProps): JSX.Element | null => {
  const { hidden, required } = useUIMetadataFieldRequirements(uiMetadataKey, editFieldKey);
  return hidden ? null : <VisitTimeSelect fieldName={fieldName} items={times} required={required} />;
};
