import { useEffect } from 'react';

import { StyledButton } from './step-button.styles';
import { VisitFormValues } from 'components/visit-form/types';
import { useFormikContext } from 'formik';
import { useIntl } from 'react-intl';

interface StepButtonProps {
  errorTranslationId?: string;
  translationId: string;
  translationValues?: Record<string, string | number>;
  dataCy?: string;
  isDisabled?: boolean;
  isEditMode?: boolean;
}
export const StepButton = ({
  errorTranslationId = 'buttons.fillInRequiredFields',
  translationId,
  translationValues,
  dataCy,
  isDisabled,
  isEditMode,
}: StepButtonProps): JSX.Element => {
  const { isValid, isValidating, validateForm } = useFormikContext<VisitFormValues>();
  const { formatMessage } = useIntl();
  const isStepValid = isDisabled || (isValid && !isValidating);
  const validStepTranslationId = isEditMode ? 'buttons.saveChanges' : translationId;
  const buttonText = formatMessage(
    { id: isStepValid ? validStepTranslationId : errorTranslationId },
    translationValues,
  );

  useEffect(() => {
    validateForm();
  }, [validateForm]);

  return <StyledButton type="submit" text={buttonText} variant="primary" disabled={!isStepValid} dataCy={dataCy} />;
};
