import { saveHostSearchValue } from 'store/hosts/actions';
import { useBooleanState } from '@hqo/react-components-library/dist/hooks/use-boolean-state';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

export const useSaveFieldValue = (): ((value: string) => void) => {
  const dispatch = useDispatch();

  return useCallback(
    (value: string) => {
      dispatch(saveHostSearchValue(value));
    },
    [dispatch],
  );
};

interface FrameStateHook {
  isOpen: boolean;
  showFrame: VoidFunction;
  hideFrame: VoidFunction;
}

export const useFrameState = (): FrameStateHook => {
  const { value: isOpen, setTrue: showFrame, setFalse: hideFrame } = useBooleanState();

  return { isOpen, showFrame, hideFrame };
};

type EventCallback = (event: React.ChangeEvent<HTMLInputElement>) => void;

export const useHandleSearch = (onChange: EventCallback, showFrame: VoidFunction): EventCallback => {
  const saveFieldValue = useSaveFieldValue();

  return useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event);
      saveFieldValue(event.target.value);
      showFrame();
    },
    [onChange, saveFieldValue, showFrame],
  );
};
