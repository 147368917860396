import styled from 'styled-components';
import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { Popover } from '@hqo/react-components-library/dist/molecules/popover';

export const SearchNameFieldWrapper = styled.div<{ isActive: boolean }>`
  position: relative;

  .search-frame {
    width: 100%;
    max-width: 428px;
    position: absolute;
    top: 15px;
  }

  .search-name-field {
    .field:focus,
    .field:hover {
      border-color: ${({ isActive, theme }) => (isActive ? 'transparent' : theme.colors.primary)};
    }
  }
`;

export const PopoverStyles = styled(Popover)`
  left: 0;
  transform: none;
  width: 100%;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  border-radius: 4px;
  box-shadow: 0px 4px 6px ${({ theme }) => theme.colorsRgba.primary_1},
    0px 0px 2px ${({ theme }) => theme.colorsRgba.primary_1};
`;

export const SearchFrameListStyle = styled.ul`
  list-style-type: none;
  background: ${({ theme }) => theme.colors.universal.white};
  margin: 45px 0 0;
  padding: 16px 0;
  border-radius: 0 0 4px 4px;
  border-top: 1px solid ${({ theme }) => theme.colors.greys.sharedMediumGrey};
`;

export const SearchFrameItem = styled.li`
  cursor: pointer;

  &:hover {
    background: ${({ theme }) => theme.colors.greys.adminGreyMedium};
  }
`;

export const SearchFrameButton = styled(Button)`
  border: none;
  width: 100%;
  text-align: left;
  justify-content: flex-start;
  padding: 8px 16px;
  border-radius: 0;
  background: ${({ theme }) => theme.colors.universal.white};
  color: ${({ theme }) => theme.colors.fontColor};

  &:hover:not([disabled]),
  &:focus,
  &:active,
  &:focus-visible {
    outline: none;
    background: ${({ theme }) => theme.colors.greys.adminGreyMedium};
    color: ${({ theme }) => theme.colors.fontColor};
  }
`;
