import { CircularArrows } from '@hqo/react-components-library';
import styled from 'styled-components';

export const VisitDetailsInfo = styled.div`
  display: flex;
  white-space: nowrap;
  flex-direction: column;
  flex-grow: 0;
  margin-left: 8px;
  width: 100%;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
`;

export const CircularArrowsIcon = styled(CircularArrows)`
  color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
`;

export const RecurrenceWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
  margin-bottom: 8px;
`;
