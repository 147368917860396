import { CalendarIcon } from './add-to-calendar.styles';

import { EditingLink } from 'components/visit-modal/visit-details/editing-link';
import { Step } from 'components/visit-form/types';
import { VisitDetailsCard } from 'components/visit-modal/visit-details/visit-details-card';
import { RecurrenceData, useScheduleData } from 'hooks/use-schedule-data';
import { VisitCancel } from '../visit-cancel';
import { VisitStatus } from 'store/visits/types';
import { TimeSlots } from 'components/visit-modal/visit-details/visit-details-card/time-slots';
import { useTimeSlots } from 'components/visit-modal/visit-details/add-to-calendar/add-to-calendar.hooks';

interface VisitDetailsProps {
  arrivalDate?: Date;
  departureDate?: Date;
  visitStatus?: VisitStatus;
  recurrenceData: RecurrenceData;
}

export const AddToCalendar = ({
  arrivalDate,
  departureDate,
  recurrenceData,
  visitStatus,
}: VisitDetailsProps): JSX.Element => {
  const recurringText = useScheduleData(recurrenceData);
  const timeSlots = useTimeSlots(arrivalDate, departureDate);
  return (
    <VisitDetailsCard
      leftIcon={<CalendarIcon size="sm" variant="primary" />}
      recurringText={recurringText}
      rightIcon={<EditingLink step={Step.Date} />}
      cancelAction={<VisitCancel arrivalDate={arrivalDate} visitStatus={visitStatus} />}
    >
      <TimeSlots timeSlots={timeSlots} />
    </VisitDetailsCard>
  );
};
