import React from 'react';
import { DynamicFormDto } from '@hqo/dynamic-forms-sdk/types';
import { FormDefinitionDto } from './form-definition-dto.context';

interface FormDefinitionDtoProviderProps {
  children: React.ReactNode;
  formDefinitionDto: DynamicFormDto;
}

export const FormDefinitionDtoProvider = ({
  children,
  formDefinitionDto,
}: FormDefinitionDtoProviderProps): JSX.Element => {
  return <FormDefinitionDto.Provider value={formDefinitionDto}>{children}</FormDefinitionDto.Provider>;
};
