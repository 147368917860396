import styled from 'styled-components';
import { FieldLabel } from '@hqo/react-components-library/dist/atoms/field-label';

export const CheckboxWrapper = styled.div`
  margin-bottom: 32px;
`;

export const FieldLabelStyles = styled(FieldLabel)`
  display: block;
  margin-bottom: 16px;
`;

export const CheckboxHqoStyles = styled.div<{ checked: boolean }>`
  margin-bottom: 8px;

  .cbx-icon svg {
    color: ${({ theme, checked }) => (checked ? theme.colors.primary : theme.colors.greys.adminGreyDark)};
  }
`;
