import { VisitCreationFlow, VisitFormValues } from 'components/visit-form/types';

import { FormInput } from 'components/visit-form/shared/form-input';
import { Visit } from 'store/visits/types';
import { VisitorRegistrationFlagProperties } from 'store/ui-metadata/types';
import { useFormikContext } from 'formik';

interface VisitorFieldsProps {
  fieldNamePrefix: string;
}
interface FieldsDataProps {
  fieldName: string;
  uiMetadataKey?: keyof VisitorRegistrationFlagProperties;
  required?: boolean;
  type?: string;
  editFieldKey?: keyof Visit;
}
const basicVisitorFieldsData: FieldsDataProps[] = [
  { fieldName: 'firstName', required: true, editFieldKey: 'first_name' },
  { fieldName: 'lastName', required: true, editFieldKey: 'last_name' },
];

const standardVisitorFieldsOnly: FieldsDataProps[] = [
  { fieldName: 'email', uiMetadataKey: 'visitor_email', type: 'email' },
];
const getAvailableFields = (visitCreationFlow: VisitCreationFlow | null): FieldsDataProps[] => [
  ...basicVisitorFieldsData,
  ...(visitCreationFlow === VisitCreationFlow.STANDARD ? standardVisitorFieldsOnly : []),
];

export const VisitorFields = ({ fieldNamePrefix }: VisitorFieldsProps): JSX.Element => {
  const {
    values: { visitCreationFlow },
  } = useFormikContext<VisitFormValues>();
  return (
    <>
      {getAvailableFields(visitCreationFlow).map(({ fieldName, ...props }) => (
        <FormInput
          key={fieldName}
          fieldName={`${fieldNamePrefix}.${fieldName}`}
          translationKey={fieldName}
          {...props}
        />
      ))}
    </>
  );
};
