import { Confirmed, Pending, Cancel } from '@hqo/react-components-library/dist/icons';
import { ConfirmedWrapper, PendingWrapper, CanceledWrapper } from './visit-status.styles';

interface StatusIconProps {
  isVisitCanceled: boolean;
  isVipVisit: boolean;
}

export const StatusIcon = ({ isVipVisit, isVisitCanceled }: StatusIconProps): JSX.Element => {
  if (isVisitCanceled) {
    return <CanceledWrapper>{Cancel}</CanceledWrapper>;
  }

  return isVipVisit ? <PendingWrapper>{Pending}</PendingWrapper> : <ConfirmedWrapper>{Confirmed}</ConfirmedWrapper>;
};
