import { CheckInBlock } from './check-in-block';
import { Visit } from 'store/visits/types';
import { useCheckInOption } from './check-in.hooks';

interface CheckInBlockDisplayProps {
  data: Visit | null;
}

export const CheckInBlockDisplay = ({ data }: CheckInBlockDisplayProps): JSX.Element => {
  const checkInOption = useCheckInOption(data?.visit_type_id);

  return (
    <>
      {checkInOption && <CheckInBlock data={checkInOption} translationKey="options" datTestId="check-in-option" />}
      {data?.special_instruction_for_visitor && (
        <CheckInBlock
          data={data?.special_instruction_for_visitor}
          translationKey="instructions"
          datTestId="check-in-instructions"
        />
      )}
      {data?.reason_for_visit && (
        <CheckInBlock data={data?.reason_for_visit} translationKey="reason" datTestId="check-in-reason" />
      )}
    </>
  );
};
