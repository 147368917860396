/* eslint-disable max-lines-per-function */
import { CreateVisitDto, Visitor } from 'store/visits/types';
import { Host, SelectType } from 'store/hosts/types';
import moment, { Moment } from 'moment-timezone';

import { User } from 'store/user/types';
import { VisitFormValues, VisitorFormValues } from 'components/visit-form/types';
import { VisitTypeData } from 'store/building/types';
import { objectMap } from '../shared/form.utility';

export function parseTime(timeString: string): [number, number] {
  const [hoursString, minutesString] = timeString.split(':');

  return [parseInt(hoursString, 10), parseInt(minutesString, 10)];
}

export const getVisitTypeId = (visitTypes: VisitTypeData[] | null, value: string): number | undefined => {
  const formattedCode = (code: string) => code.replace(/-/g, '_');

  return visitTypes?.find((item: VisitTypeData) => value.toUpperCase() === formattedCode(item.code))?.id ?? undefined;
};

function mapVisitors(formValues: VisitFormValues, visitTypes: VisitTypeData[] | null): Visitor[] {
  return formValues.visitors.map((visitor) => ({
    id: visitor.id,
    first_name: visitor.firstName,
    last_name: visitor.lastName,
    phone: visitor.phone,
    email: visitor.email,
    visit_type_id: getVisitTypeId(visitTypes, visitor.checkInOptions),
    special_instruction_for_visitor: visitor.checkInInstructions,
    reason_for_visit: visitor.reasonForVisit,
    is_premier: visitor.isPremier,
  }));
}

const mapEmailRecipients = (visitors: VisitorFormValues[]) => {
  return visitors.reduce<string[]>((acc, visitor) => {
    return visitor.emailRecipients ? [...acc, ...visitor.emailRecipients] : acc;
  }, []);
};

export const convertToBuildingTimezone = (date: Moment, timeZone: string): Moment => {
  const offset = moment().tz(timeZone).utcOffset() - moment().utcOffset();

  return date.clone().subtract(offset, 'minutes');
};

const getDateTime = (dateMoment: Moment, time: string, timeZone: string): Date => {
  const [hour, minute] = parseTime(time);

  return convertToBuildingTimezone(dateMoment.clone().set({ hour, minute }), timeZone).toDate();
};

const getDates = (
  formValues: VisitFormValues,
  timeZone: string,
): Pick<CreateVisitDto, 'arrival_date' | 'departure_date'> => {
  const { endTime, startTime, visitDate, visitEndDate } = formValues.date;
  const arrivalDate = visitDate ? moment(visitDate) : moment();
  const departureDate = visitEndDate ? moment(visitEndDate) : arrivalDate;

  return {
    arrival_date: getDateTime(arrivalDate, startTime ?? '00:00', timeZone),
    departure_date: getDateTime(departureDate, endTime ?? '23:59', timeZone),
  };
};

const getHostEmail = (currentUser: User | null, hosts: Host[] | null, formValues: string) => {
  if (formValues === '') {
    return currentUser?.email;
  }

  return hosts?.find((host) => `${host.first_name} ${host.last_name}` === formValues)?.email;
};

export const mapFormValueToDto = (
  formValues: VisitFormValues,
  timeZone: string,
  visitTypes: VisitTypeData[] | null,
  currentUser: User | null,
  hosts: Host[] | null,
): CreateVisitDto => {
  const mappedVisitors = mapVisitors(formValues, visitTypes);
  const mappedEmailRecipients = mapEmailRecipients(formValues.visitors);
  return objectMap(
    {
      ...mappedVisitors[0],
      ...getDates(formValues, timeZone),
      id: formValues.id,
      visitors: mappedVisitors,
      host_email: getHostEmail(currentUser, hosts, formValues.host.hostName),
      floor: formValues.host.floor,
      suite: formValues.host.suite,
      escort_id: formValues.escort.id,
      special_visit_type: formValues.specialVisitType,
      special_instruction_for_escort: formValues.escort.instructions,
      email_recipients: mappedEmailRecipients.length ? mappedEmailRecipients : undefined,
      frequency: formValues.date.frequency,
      end_date: formValues.date.endDate,
      count: formValues.date.count,
      custom_fields: formValues.custom_fields,
    },
    (value: unknown) => {
      if (typeof value === 'string') {
        return value === SelectType.UNSELECTED ? undefined : value.trim() || undefined;
      }
      return value ?? undefined;
    },
  ) as CreateVisitDto;
};
