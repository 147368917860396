import { Redirect } from 'react-router';
import { useNextStepUrl } from './use-next-step-url.hook';

export const NextStepRedirect = (): JSX.Element | null => {
  const nextStepUrl = useNextStepUrl();

  if (!nextStepUrl) {
    return null;
  }

  return <Redirect to={nextStepUrl} />;
};
