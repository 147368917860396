import { NextStepRedirect } from 'components/visit-form/shared/next-step-redirect';
import { FlowSelect } from 'components/visit-form/steps/flow-step/flow-select';
import { VisitCreationFlow } from 'components/visit-form/types';
import { Route, Switch, useRouteMatch } from 'react-router';

export const FlowStep = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path} exact component={FlowSelect} />
      <Route path={`${path}/:flowType(${VisitCreationFlow.STANDARD})`} component={NextStepRedirect} />
      <Route path={`${path}/:flowType(${VisitCreationFlow.CONTACT_WAIVER})`} component={NextStepRedirect} />
    </Switch>
  );
};
