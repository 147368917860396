import { ButtonsWrapper, NoButton, YesButton } from './confirmation-modal.styles';

import { useIntl } from 'react-intl';

interface ButtonsProps {
  yesButtonTranslationId: string;
  noButtonTranslationId: string;
  onYesClick?: VoidFunction;
  onNoClick?: VoidFunction;
}

export const Buttons = ({
  noButtonTranslationId,
  yesButtonTranslationId,
  onNoClick: handleNoClick,
  onYesClick: handleYesClick,
}: ButtonsProps): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <ButtonsWrapper>
      <YesButton
        text={formatMessage({ id: yesButtonTranslationId })}
        onClick={handleYesClick}
        dataCy="yes-button"
        data-testid="yes-button"
      />
      <NoButton
        text={formatMessage({ id: noButtonTranslationId })}
        variant="cancelButton"
        onClick={handleNoClick}
        dataCy="no-button"
        data-testid="no-button"
      />
    </ButtonsWrapper>
  );
};
