import { Redirect, Route, Switch, useRouteMatch } from 'react-router';

import { EscortConfirmation } from 'components/visit-form/steps/escort-step/escort-confirmation';
import { TenantProvidedEscort } from './tenant-provided-escort';
import { NextStepRedirect } from 'components/visit-form/shared/next-step-redirect';
import { StepProps } from 'components/visit-form/steps/step.props.interface';
import { useFormikContext } from 'formik';
import { VisitFormValues, SpecialVisitType } from 'components/visit-form/types';
import { ERROR_PATH } from 'shared/consts';

export const EscortStep = ({ isEditMode }: StepProps): JSX.Element => {
  const { values } = useFormikContext<VisitFormValues>();
  const { path } = useRouteMatch();

  if (isEditMode && !values.specialVisitType) {
    return <Redirect to={ERROR_PATH} />;
  }

  return (
    <Switch>
      <Route path={path} exact>
        <EscortConfirmation isEditMode={isEditMode} />
      </Route>
      <Route path={`${path}/:specialVisitType(${SpecialVisitType.ESCORT})`}>
        <TenantProvidedEscort isEditMode={isEditMode} />
      </Route>
      <Route path={`${path}/:specialVisitType(${SpecialVisitType.VIP})`} component={NextStepRedirect} />
    </Switch>
  );
};
