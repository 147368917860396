import styled, { css } from 'styled-components';

import { Button } from '@hqo/react-components-library/dist/atoms/button';
import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';

export const StyledButton = styled(Button)`
  height: 50px;
  width: 240px;
  padding: 16px;
  font-weight: bold;
  font-size: 16px;
  margin-top: auto;
  &:disabled {
    &,
    &:hover {
      opacity: 1;
      background-color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
      border-color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
    }
  }

  ${mediaMaxWidth(css`
    width: 100%;
  `)}
`;
