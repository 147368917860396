import { PaginationResult } from 'shared/types';
import { StandardPaginationResult } from '../../shared/types/standard-pagination-result';

export interface Host {
  venue_id: string;
  first_name: string;
  last_name: string;
  email?: string;
  phone?: string;
}

export type Hosts = PaginationResult<Host> | StandardPaginationResult<Host, 'hosts'>;

export interface HostState {
  loading: boolean;
  error: Error | null;
  data: Host[] | null;
  fieldValue: string;
}

export interface FieldValueParams {
  fieldValue: string;
}

export interface HostSearchResultParams {
  ownerId: string;
  fieldValue: string;
}

export enum SelectType {
  UNSELECTED = 'unselected',
}
