import { MouseEventHandler } from 'react';

import { NoSearchResult } from './no-search-result';
import { PopoverStyles } from './search-name-field.styles';
import { SearchFrameList } from './search-frame-list';
import { useHostSearchResult } from 'store/hosts/selectors';

interface SearchFrameProps {
  selectHostName: MouseEventHandler;
  onCloseFrame: VoidFunction;
  value: string;
  open: boolean;
}

export const SearchFrame = ({ selectHostName, onCloseFrame, value, open }: SearchFrameProps): JSX.Element => {
  const hostData = useHostSearchResult();

  return (
    <PopoverStyles
      wrapperClassName="search-frame"
      position="bottom"
      open={open}
      alignment="center"
      onClose={onCloseFrame}
      buttonComponent={null}
    >
      {hostData?.length ? (
        <SearchFrameList hostData={hostData} selectHostName={selectHostName} />
      ) : (
        <NoSearchResult fieldValue={value} />
      )}
    </PopoverStyles>
  );
};
