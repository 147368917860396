import { DynamicFormBuilder } from '@hqo/dynamic-forms-sdk/builder';
import { DynamicForm, DynamicFormData } from '@hqo/dynamic-forms-sdk/form';
import { DynamicFormDto } from '@hqo/dynamic-forms-sdk/types';
import { DynamicFormDataDto } from '@hqo/dynamic-forms-sdk/types/form/form-data.types';
import { useMemo } from 'react';

export const useFormDefinition = (
  formDefinitionDto: DynamicFormDto,
  formValues: DynamicFormDataDto = {},
): [DynamicForm | null, Error | null] => {
  return useMemo(() => {
    if (formDefinitionDto) {
      try {
        return [
          DynamicFormBuilder.fromJSON(formDefinitionDto)
            .setValues(DynamicFormData.fromFormDataJson(formValues))
            .build(),
          null,
        ];
      } catch (error) {
        return [null, error];
      }
    }

    return [null, null];
  }, [formDefinitionDto, formValues]);
};
