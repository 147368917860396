import styled from 'styled-components';

export const Answer = styled.div`
  margin-bottom: 8px;
`;

export const Question = styled(Answer)`
  font-weight: 700;
  margin-top: 5px;
`;
