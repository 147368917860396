import { AddButtonStyles } from './add-visitor-button.style';
import { Plus } from '@hqo/react-components-library/dist/icons';
import { useIntl } from 'react-intl';

interface AddVisitorButtonProps {
  text: string;
  clickHandle: VoidFunction;
  isStepValid: boolean;
  dataCy: string;
}

export const AddVisitorButton = ({ text, clickHandle, isStepValid, dataCy }: AddVisitorButtonProps): JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <AddButtonStyles
      variant="link"
      onClick={clickHandle}
      text={formatMessage({ id: text })}
      Icon={<Plus />}
      iconDirection="left"
      disabled={!isStepValid}
      data-testid="add-visitor-button"
      dataCy={dataCy}
    />
  );
};
