import { MouseEventHandler } from 'react';
import { SearchFrameListStyle, SearchFrameButton, SearchFrameItem } from './search-name-field.styles';
import { Host } from 'store/hosts/types';

interface SearchFrameListProps {
  hostData: Host[];
  selectHostName: MouseEventHandler;
}

export const SearchFrameList = ({ hostData, selectHostName }: SearchFrameListProps): JSX.Element => (
  <SearchFrameListStyle>
    {hostData.map((item) => (
      <SearchFrameItem key={item.last_name}>
        <SearchFrameButton
          onClick={selectHostName}
          text={`${item.first_name} ${item.last_name}`}
          data-testid={`${item.first_name}-${item.last_name}-host`}
        />
      </SearchFrameItem>
    ))}
  </SearchFrameListStyle>
);
