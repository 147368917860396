import { StepButton } from 'components/visit-form/shared/step-wrapper/step-button';
import { StepTitle } from 'components/visit-form/shared/step-wrapper/step-title';
import { VisitDate, VisitEndDate } from './visit-date';
import { VisitFormHeader } from 'components/visit-form/shared/step-wrapper/visit-form-header';
import { VisitTimes } from 'components/visit-form/steps/date-step/visit-times';
import { ContentWrapper } from 'components/visit-form/shared/step-wrapper/step-wrapper.styles';
import { StepProps } from 'components/visit-form/steps/step.props.interface';
import { RecurringData } from './recurring-data/recurring-data';
import { useFieldMetadata } from 'store/ui-metadata/hooks';
import { useStepNumber } from 'components/visit-form/step.hooks';
import { useMemo } from 'react';

export const DateStep = ({ isEditMode }: StepProps): JSX.Element => {
  const stepNumber = useStepNumber();
  const translationValues = useMemo(() => ({ index: stepNumber + 1 }), [stepNumber]);
  const hasCustomFormStep = !!useFieldMetadata('custom_fields');
  const buttonTranslationId = hasCustomFormStep ? 'buttons.continueToStep' : 'buttons.submitNewRegistration';
  const visitEndDateInputEnabled = useFieldMetadata('visit_end_date_input_enabled');

  return (
    <>
      <VisitFormHeader isEditMode={isEditMode} />
      <ContentWrapper>
        <StepTitle translationId="labels.newVisit.dateStep.title" dataCy="create-visit-date-step-title" />
        <VisitDate />
        <VisitTimes>{visitEndDateInputEnabled && <VisitEndDate />}</VisitTimes>
        <RecurringData />
        <StepButton
          translationId={buttonTranslationId}
          translationValues={translationValues}
          dataCy="submit-form-button"
          isEditMode={isEditMode}
        />
      </ContentWrapper>
    </>
  );
};
