import { useField } from 'formik';

import { CheckboxHqo } from '@hqo/react-components-library/dist/molecules/checkbox-hqo';
import { useIntl } from 'react-intl';
import { useFieldMetadata } from 'store/ui-metadata/hooks';

interface PremierCheckboxProps {
  fieldNamePrefix: string;
}

export const PremierCheckbox = ({ fieldNamePrefix }: PremierCheckboxProps): JSX.Element | null => {
  const intl = useIntl();
  const [{ value, onChange }] = useField(`${fieldNamePrefix}.isPremier`);
  const isPremierEnabled = useFieldMetadata('is_premier_enabled');

  if (!isPremierEnabled) {
    return null;
  }

  return (
    <CheckboxHqo
      id="checkbox-isPremier"
      label={intl.formatMessage({ id: 'labels.isPremier' })}
      name={`${fieldNamePrefix}.isPremier`}
      checked={value}
      onChange={onChange}
      data-cy={`${fieldNamePrefix}.isPremier`}
    />
  );
};
