import {
  NotifyOnEmail,
  NotifyOnWrapper,
  NotifyOnTitle,
} from 'components/visit-modal/visit-details/notify-on-check-in/styles';
import { useIntl } from 'react-intl';

interface Props {
  data: string[];
}

export const NotifyUponCheckIn = ({ data }: Props) => {
  const { formatMessage } = useIntl();

  return (
    <NotifyOnWrapper data-testId="notify-wrapper">
      <NotifyOnTitle>{formatMessage({ id: `labels.notifyUponCheckIn` })}</NotifyOnTitle>
      {data?.map((email, index) => (
        <NotifyOnEmail key={email} data-testId={`email-recipient-${index}`}>
          {email}
        </NotifyOnEmail>
      ))}
    </NotifyOnWrapper>
  );
};
