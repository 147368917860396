import { PageLoader } from 'components/root/page-loader';
import React from 'react';
import { useExternalLogin } from 'hooks/use-external-login.hook';
import { AppContent } from './app-content';

export const App: React.FC = (): JSX.Element => {
  const shouldWaitForLogin = useExternalLogin();

  if (shouldWaitForLogin) {
    return <PageLoader />;
  }

  return <AppContent />;
};
