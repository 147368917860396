import { Divider, EscortFullName, EscortInfo, EscortItemWrapper, EscortPanelTitle } from './escort-item.styles';

import { FormattedMessage } from 'react-intl';
import { useEscortsState } from 'store/escorts/hooks';
import { EditingLink } from 'components/visit-modal/visit-details/editing-link';
import { Step } from 'components/visit-form/types';

interface EscortItemProps {
  escortId: string;
}

export const EscortItem = ({ escortId }: EscortItemProps): JSX.Element | null => {
  const escortData = useEscortsState();
  const escort = escortData?.data !== null && escortData.data.find(({ id }) => id === escortId);

  if (escort) {
    return (
      <>
        <Divider />
        <EscortItemWrapper>
          <EscortInfo>
            <EscortPanelTitle>
              <span>
                <FormattedMessage id="labels.escort.title" />
              </span>
              <EditingLink step={Step.Escort} />
            </EscortPanelTitle>
            <EscortFullName>{`${escort.first_name} ${escort.last_name}`}</EscortFullName>
          </EscortInfo>
        </EscortItemWrapper>
      </>
    );
  }
  return null;
};
