import styled from 'styled-components';
import { BottomModal } from '@hqo/react-components-library/dist/molecules/modals/bottomModal';

export const BottomModalStyled = styled(BottomModal)`
  .fa-circle-xmark {
    --fa-primary-color: ${({ theme }) => theme.colors.$adminPink};
    --fa-secondary-color: ${({ theme }) => theme.colors.$adminPink};
    --fa-secondary-opacity: 0.1;

    padding: 0;
    font-size: 45px;
  }

  button {
    margin: 6px;

    &:first-of-type {
      background-color: ${({ theme }) => theme.colors.$adminPink};
    }
  }

  .background-container {
    // TODO: fix modal magic z-index values - z-index: 13; z-index: 14;
    //  https://github.com/HqOapp/react-components-library/blob/master/src/molecules/modals/mobileWrapper/constants.tsx
    z-index: 14;
  }
`;
