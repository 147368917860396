import { ChooseScreenForm } from 'components/visit-form/shared/choose-screen/choose-screen-form';
import { VisitFormHeader } from 'components/visit-form/shared/step-wrapper/visit-form-header';
import { VisitFormValues, SpecialVisitType } from 'components/visit-form/types';
import { useFormikContext } from 'formik';
import { StepProps } from 'components/visit-form/steps/step.props.interface';
import { Redirect, useRouteMatch } from 'react-router';
import { useTitleTranslationValues } from './escort-confirmation.hooks';

const escortOptions = [
  { value: SpecialVisitType.ESCORT, label: 'labels.escort.confirmation.options.escort' },
  { value: SpecialVisitType.VIP, label: 'labels.escort.confirmation.options.contactWaiver' },
];

export const EscortConfirmation = ({ isEditMode }: StepProps): JSX.Element => {
  const { values } = useFormikContext<VisitFormValues>();
  const { url } = useRouteMatch();
  const titleTranslationValues = useTitleTranslationValues(values.visitors);

  if (isEditMode) {
    return <Redirect to={`${url}/${values.specialVisitType}`} />;
  }

  return (
    <>
      <VisitFormHeader isEditMode={isEditMode} />
      <ChooseScreenForm
        fieldName="specialVisitType"
        titleTranslationId="labels.escort.confirmation.header"
        titleValues={titleTranslationValues}
        subtitleTranslationId="labels.escort.confirmation.subtitle"
        subtitle2TranslationId="labels.escort.confirmation.subtitle2"
        buttonTranslationId="buttons.continue"
        dataCy="escort-step-confirmation"
        options={escortOptions}
        hideContinueUntilSelected
      />
    </>
  );
};
