import styled from 'styled-components';

export const TopText = styled.span`
  color: ${({ theme }) => theme.colors.fontColor};
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
`;

export const BottomText = styled.span`
  color: ${({ theme }) => theme.colors.greys.sharedDarkGrey};
  font-size: 16px;
  margin-bottom: 8px;
`;
