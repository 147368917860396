import { useIntl } from 'react-intl';
import { ErrorNotificationWrapper } from './no-search-result.styles';

import { Notification } from '@hqo/react-components-library/dist/molecules/notification';

interface NoSearchResultProps {
  fieldValue: string;
}

export const NoSearchResult = ({ fieldValue }: NoSearchResultProps): JSX.Element => {
  const { formatMessage } = useIntl();

  const hostFullName = `"${fieldValue}"`;

  return (
    <ErrorNotificationWrapper>
      <Notification
        className="no-search-result"
        title={formatMessage({ id: 'notifications.searchError.title' })}
        description={formatMessage({ id: 'notifications.searchError.description' }, { hostFullName })}
        buttonText={formatMessage({ id: 'notifications.searchError.buttonText' })}
        dataCy="no-search-result"
        dataTestId="no-search-result"
        iconName="search"
      />
    </ErrorNotificationWrapper>
  );
};
