import { SlotContainer } from './time-slots.styles';
import { DetailsBlock } from './details-block';

export interface TimeSlotData {
  date: string;
  time?: string;
}

interface TimeSlotProps extends TimeSlotData {
  dataCy: string;
}

export const TimeSlot = ({ date, time, dataCy }: TimeSlotProps): JSX.Element => (
  <SlotContainer>
    <DetailsBlock dataCy={dataCy} topText={date} bottomText={time} />
  </SlotContainer>
);
