import { SelectButtonsWrapper, FieldLabelSpan } from './select-field.styles';
import { SelectButtonField } from '@hqo/react-components-library/dist/molecules/SelectButtonField';
import { FieldLabel } from '@hqo/react-components-library/dist/atoms/field-label';
import { useSelectFieldOptions } from 'components/visit-form/steps/visitor-step/select-field/select-field.hooks';
import { SelectFieldProps } from 'components/visit-form/steps/visitor-step/select-field/select-field.interface';

export const SelectField = (props: SelectFieldProps): JSX.Element | null => {
  const { hidden, label, placeholder, required, handleChange, value } = useSelectFieldOptions(props);
  const { fieldName, options } = props;

  return !hidden ? (
    <>
      <FieldLabel label={label} required={required}>
        <FieldLabelSpan>{placeholder}</FieldLabelSpan>
      </FieldLabel>
      <SelectButtonsWrapper>
        <SelectButtonField
          options={options}
          name={fieldName}
          variant="outlined"
          sizeOption="sm"
          onChange={handleChange}
          value={value}
        />
      </SelectButtonsWrapper>
    </>
  ) : null;
};
