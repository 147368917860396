import { TimeSlot, TimeSlotData } from './time-slot';
import { Container } from './time-slots.styles';

interface TimeSlotsProps {
  timeSlots: TimeSlotData[];
}

export const TimeSlots = ({ timeSlots }: TimeSlotsProps): JSX.Element => (
  <Container>
    {timeSlots.map(({ date, time }) => (
      <TimeSlot key={date} dataCy={`time-slot-${date}`} date={date} time={time} />
    ))}
  </Container>
);
