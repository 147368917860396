import { VisitorRegistrationFlagProperties } from 'store/ui-metadata/types';
import { useFieldTranslations } from 'hooks/use-field-traslations.hooks';
import { useMemo } from 'react';
import { useUIMetadataFieldRequirements } from '../form.hooks';

interface selectData {
  hidden: boolean;
  label: string;
  placeholder: string;
  required: boolean;
}

export const useSelectData = (
  translationKey: string,
  metadataKey?: keyof VisitorRegistrationFlagProperties,
): selectData => {
  const { label, placeholder } = useFieldTranslations(translationKey);
  const { hidden, required } = useUIMetadataFieldRequirements(metadataKey);

  return useMemo(() => ({ label, placeholder, hidden, required }), [label, placeholder, hidden, required]);
};
