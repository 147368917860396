import { BackIcon, StyledLink } from './back-link.styles';

import { FormattedMessage } from 'react-intl';
import { VisitFormRouteParams } from 'components/visit-form/types';
import { usePreviousScreen } from 'components/visit-form/visit-steps-config/visit-screens.hooks';
import { useRouteMatch } from 'react-router';

interface BackLinkProps {
  className?: string;
  isEditMode?: boolean;
}
export const BackLink = ({ className, isEditMode }: BackLinkProps): JSX.Element => {
  const { url } = useRouteMatch<VisitFormRouteParams>();
  const previousScreen = usePreviousScreen();
  const backUrl = isEditMode
    ? url.replace(/\/edit.+/, '')
    : url.replace(/\/new.+/, previousScreen ? `/new/${previousScreen}` : '');

  return (
    <StyledLink to={backUrl} className={className} data-cy="back-link" data-testid="back-link">
      <BackIcon size="lg" />
      <span>
        <FormattedMessage id="buttons.back" />
      </span>
    </StyledLink>
  );
};
