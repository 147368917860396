import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';
import { StyledButton } from 'components/visit-form/shared/step-wrapper/step-button/step-button.styles';
import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';

export const ContinueButtonWrapper = styled(Link)`
  text-decoration: none;
  margin-top: 20px;
  align-self: flex-start;

  ${mediaMaxWidth(css`
    margin-top: auto;
    width: 100%;
  `)}
`;

export const ContinueButtonStyled = styled(StyledButton)`
  height: 36px;
  width: auto;
  padding: 0 24px;

  ${mediaMaxWidth(css`
    height: 50px;
    width: 100%;
  `)}
`;
