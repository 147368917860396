import { useField } from 'formik';

import { FormPhoneInput } from '@hqo/react-components-library';
import { useFieldTranslations } from 'hooks/use-field-traslations.hooks';
import { useUIMetadataFieldRequirements } from 'components/visit-form/shared/form.hooks';
import { FieldWrapper } from 'components/visit-form/shared/field-wrapper';
import { VisitorRegistrationFlagProperties } from 'store/ui-metadata/types';
import { useLocale } from 'shared/hooks';

interface InternationalPhoneInputProps {
  fieldNamePrefix: string;
  uiMetadataKey?: keyof VisitorRegistrationFlagProperties;
}

export const InternationalPhoneInput = ({
  fieldNamePrefix,
  uiMetadataKey,
}: InternationalPhoneInputProps): JSX.Element | null => {
  const { placeholder, label } = useFieldTranslations('phone');

  const { hidden, required } = useUIMetadataFieldRequirements(uiMetadataKey);
  const locale = useLocale();
  const [, country] = locale?.split(/_|-/) ?? [];
  const [{ value, onBlur }, { error, touched }, { setValue }] = useField({ name: `${fieldNamePrefix}.phone` });
  const isErrored = touched && !!error;

  return hidden ? null : (
    <FieldWrapper touched={touched} error={error}>
      <FormPhoneInput
        fieldName={`${fieldNamePrefix}.phone`}
        placeholder={placeholder}
        label={label}
        hidden={hidden}
        onBlur={onBlur}
        value={value}
        setValue={setValue}
        country={country}
        dataCy="phone-input"
        required={required}
        isErrored={isErrored}
      />
    </FieldWrapper>
  );
};
