import moment, { Moment } from 'moment';
import { FieldWrapper } from 'components/visit-form/shared/field-wrapper';
import { useFieldTranslations } from 'hooks/use-field-traslations.hooks';
import { useLocale } from 'shared/hooks';
import { StyledDatePicker } from './visit-date-picker.styles';

interface VisitDatePickerProps {
  momentValue: Moment | null;
  handleChange: (newValue: Moment) => void;
  touched: boolean;
  error: string | undefined;
  fieldName: string;
  minDate?: Moment;
}

const today = moment().startOf('day');

export const VisitDatePicker = ({
  momentValue,
  handleChange,
  touched,
  error,
  fieldName,
  minDate,
}: VisitDatePickerProps): JSX.Element => {
  const { label, placeholder } = useFieldTranslations(fieldName);
  const locale = useLocale();

  return (
    <FieldWrapper touched={touched} error={error}>
      <StyledDatePicker
        value={momentValue}
        onChange={handleChange}
        label={label}
        locale={locale}
        placeholder={placeholder}
        variant={touched && !!error ? 'error' : 'normal'}
        minDate={minDate ?? today}
        required
        onlyCurrentMonthAvailable={false}
        dataCy="visit-date-picker"
        size="sm"
      />
    </FieldWrapper>
  );
};
