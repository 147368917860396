import { FormattedMessage, useIntl } from 'react-intl';
import {
  VisitStatusDescription,
  VisitStatusTitle,
  VisitStatusTitleWrapper,
  VisitStatusWrapper,
} from './visit-status.styles';

import { SpecialVisitType } from 'components/visit-form/types';
import { StatusIcon } from './status-icon';
import { useDateTimeFormatters } from 'shared/hooks';
import { VisitStatus as VisitStatusEnum } from 'store/visits/types';

interface VisitStatusProps {
  specialVisitType?: string | null;
  arrivalDate?: Date;
  visitStatus?: VisitStatusEnum;
}

export const VisitStatus = ({ arrivalDate, specialVisitType, visitStatus }: VisitStatusProps): JSX.Element => {
  const { formatDate, formatTime } = useDateTimeFormatters({
    dateFormat: { dateStyle: 'full' },
  });
  const arrivalDateFormatted = formatDate(arrivalDate);
  const arrivalTimeFormatted = formatTime(arrivalDate);
  const isVipVisit = specialVisitType === SpecialVisitType.VIP;

  const isVisitCanceled = visitStatus === VisitStatusEnum.CANCELED;
  const visitCanceledTitle = 'labels.visit.status.canceled';
  const visitInvitedTitle = isVipVisit ? 'labels.visit.status.pending' : 'labels.visit.status.confirmed';
  const visitStatusTitle = isVisitCanceled ? visitCanceledTitle : visitInvitedTitle;
  const { formatMessage } = useIntl();
  const visitStatusDescription = formatMessage(
    { id: `labels.visit.status.${isVisitCanceled ? 'descriptionCanceled' : 'description'}` },
    { arrivalDate: arrivalDateFormatted, arrivalTime: arrivalTimeFormatted },
  );

  return (
    <VisitStatusWrapper>
      <VisitStatusTitleWrapper>
        <StatusIcon isVipVisit={isVipVisit} isVisitCanceled={isVisitCanceled} />
        <VisitStatusTitle data-cy="visit-status-title">
          <FormattedMessage id={visitStatusTitle} />
        </VisitStatusTitle>
      </VisitStatusTitleWrapper>
      <VisitStatusDescription data-cy="visit-status-description">{visitStatusDescription}</VisitStatusDescription>
    </VisitStatusWrapper>
  );
};
