import { HelperText, SubtitleText } from './helper-text';

import { ChooseButtons } from './choose-buttons';
import { ContinueButton } from './continue-button/continue-button';
import { Option } from '../choose-screen-form-data';
import { StepContainer } from '../choose-screen.styles';
import { StepTitle } from 'components/visit-form/shared/step-wrapper/step-title';
import { useField } from 'formik';

interface ChooseScreenFormProps {
  fieldName: string;
  titleTranslationId: string;
  helperTextTranslationId?: string;
  subtitleTranslationId?: string;
  subtitle2TranslationId?: string;
  buttonTranslationId: string;
  dataCy: string;
  options: Option[];
  titleValues?: Record<string, string | number>;
  hideContinueUntilSelected?: boolean;
}

export const ChooseScreenForm = ({
  fieldName,
  titleTranslationId,
  subtitleTranslationId,
  subtitle2TranslationId,
  helperTextTranslationId,
  buttonTranslationId,
  dataCy,
  options,
  titleValues,
  hideContinueUntilSelected,
}: ChooseScreenFormProps): JSX.Element => {
  const [{ value }] = useField(fieldName);

  return (
    <StepContainer>
      <StepTitle translationId={titleTranslationId} dataCy={`${dataCy}-title`} translationValues={titleValues} />
      <SubtitleText translationId={subtitleTranslationId} />
      <SubtitleText translationId={subtitle2TranslationId} />
      <ChooseButtons fieldName={fieldName} options={options} />
      <HelperText translationId={helperTextTranslationId} />
      <ContinueButton
        value={value}
        translationId={buttonTranslationId}
        hideContinueUntilSelected={hideContinueUntilSelected}
      />
    </StepContainer>
  );
};
