export type DeepUnknown<T> = {
  [K in keyof T]?: T[K] extends string | number | symbol | null | undefined | bigint ? unknown : DeepUnknown<T[K]>;
};
export type Key = string | symbol | number;
export type Obj = Record<Key, unknown> | Array<unknown>;
export type CallbackFunc = (value: unknown, fullPath: Key[]) => unknown;

function isPlainObject(obj: unknown): obj is Obj {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    obj.constructor === Object &&
    Object.prototype.toString.call(obj) === '[object Object]'
  );
}

export function isObjectOrArray(value: unknown): boolean {
  return Array.isArray(value) || isPlainObject(value);
}

export function objectMap<T extends Obj>(object: T, callback: CallbackFunc, parentKeys: Key[] = []): DeepUnknown<T> {
  if (!object) {
    return object;
  }
  const clonedObject = Array.isArray(object) ? [...object] : { ...object };
  const keys = [...Object.keys(object), ...Object.getOwnPropertySymbols(object)] as (keyof DeepUnknown<T>)[];
  return keys.reduce((result: DeepUnknown<T>, key) => {
    const value = result[key];
    const symbolString = key.toString();
    const fullPath = [...parentKeys, Array.isArray(object) ? `[${symbolString}]` : key];
    const newValue = isObjectOrArray(value) ? objectMap(value as T, callback, fullPath) : callback(value, fullPath);
    // eslint-disable-next-line no-param-reassign
    result[key] = newValue as DeepUnknown<T>[keyof DeepUnknown<T>];
    return result;
  }, clonedObject as DeepUnknown<T>);
}
