import styled from 'styled-components';

export const CheckInBlockWrapper = styled.div`
  margin-bottom: 32px;
`;

export const CheckInBlockTitle = styled.div`
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: bold;
  color: ${({ theme }): string => theme.colors.fontColor};
`;

export const CheckInItem = styled.div`
  font-size: 16px;
  color: ${({ theme }): string => theme.colors.fontColor};
`;
