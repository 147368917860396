import { Redirect } from 'react-router';
import { DynamicFormsClient } from '@hqo/dynamic-forms-client';
import { useState } from 'react';
import { DynamicFormDataDto } from '@hqo/dynamic-forms-sdk/types/form/form-data.types';
import {
  useDynamicFormDefinition,
  useDynamicFormsLocalizationValues,
  useDynamicFormsLocaleConfig,
} from './custom-form-hooks';
import { ERROR_PATH } from 'shared/consts';

interface CustomFormContentProps {
  onSubmit: (formDefinition: DynamicFormDataDto) => void;
}

export const CustomFormContent = ({ onSubmit: handleSubmit }: CustomFormContentProps): JSX.Element => {
  const [formDefinition, error] = useDynamicFormDefinition();
  const localizationValues = useDynamicFormsLocalizationValues();
  const localeConfig = useDynamicFormsLocaleConfig();
  const [index, setIndex] = useState(0);

  if (formDefinition === null && error === null) {
    return null;
  }

  if (formDefinition === null && error) {
    return <Redirect to={ERROR_PATH} />;
  }

  return (
    <DynamicFormsClient
      formDefinition={formDefinition}
      index={index}
      onChangeIndex={setIndex}
      onSubmit={handleSubmit}
      localizationValues={localizationValues}
      localeConfig={localeConfig}
    />
  );
};
