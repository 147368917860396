import { FormattedMessage } from 'react-intl';
import { StyledHelperText } from './helper-text.styles';

interface HelperTextProps {
  translationId?: string;
  className?: string;
}

export const HelperText = ({ translationId, className }: HelperTextProps): JSX.Element | null => {
  if (!translationId) {
    return null;
  }

  return (
    <StyledHelperText className={className}>
      <FormattedMessage id={translationId} />
    </StyledHelperText>
  );
};
