import { ConfirmationModal } from 'components/shared/confirmation-modal';
import { Modal } from 'components/shared/modal';
import { useIsEditMode } from 'components/visit-form/context/is-editing.hook';

interface CancelNewVisitorConfirmationProps {
  onCancel: VoidFunction;
  onConfirm: VoidFunction;
}

export const CancelVisitorFormConfirmation = ({
  onCancel: handleCancel,
  onConfirm: handleConfirm,
}: CancelNewVisitorConfirmationProps): JSX.Element => {
  const isEditingMode = useIsEditMode();
  const translationIdPrefix = isEditingMode ? 'labels.cancelEditVisit' : 'labels.cancelNewVisit';

  return (
    <Modal onClose={handleCancel}>
      <ConfirmationModal
        titleTranslationId={`${translationIdPrefix}.title`}
        noButtonTranslationId={`${translationIdPrefix}.noButton`}
        yesButtonTranslationId={`${translationIdPrefix}.yesButton`}
        onNoClick={handleCancel}
        onYesClick={handleConfirm}
        dataCy="cancel-visit-form-confirmation"
        dataTestId="cancel-visit-form-confirmation"
      />
    </Modal>
  );
};
