import { FormInput } from 'components/visit-form/shared/form-input';
import { VisitDate } from 'components/visit-form/steps/date-step/visit-date';
import { RecurrenceEnum } from 'components/visit-form/types';
import { useField } from 'formik';
import { FieldWrapper } from '../../../shared/field-wrapper/field-wrapper';

interface Props {
  ends: RecurrenceEnum;
}

const recurrenceHasEnd = (value: RecurrenceEnum): boolean => {
  return value === RecurrenceEnum.OCCURENCES_COUNT || value === RecurrenceEnum.DATE;
};

export const RecurrenceEndsOn = ({ ends }: Props) => {
  const [{ value: count }] = useField<number | null>('date.count');

  if (!recurrenceHasEnd(ends)) {
    return null;
  }

  return ends === RecurrenceEnum.DATE ? (
    <FieldWrapper>
      <VisitDate fieldName="endDate" />
    </FieldWrapper>
  ) : (
    <FormInput value={count} fieldName="date.count" translationKey="count" type="number" required />
  );
};
