import { Step, VisitCreationFlow, VisitFormValues } from '../types';

import { FloorData } from 'store/building/types';

export const isEscortAvailable = (values: VisitFormValues): boolean =>
  values.visitCreationFlow !== VisitCreationFlow.STANDARD;

export const isFlowStepAvailable = (isVipEnabled: boolean | null, isEscortEnabled: boolean | null): boolean =>
  !!isVipEnabled && !!isEscortEnabled;

interface GetAvailableStepsParams {
  values: VisitFormValues;
  isVipEnabled: boolean | null;
  isEscortEnabled: boolean | null;
  isHostsEnabled: boolean | null;
  availableFloors: FloorData[] | null;
  isCustomFieldsStepAvailable: boolean | null;
}

const getFlowStep = ({
  isVipEnabled,
  isEscortEnabled,
}: Pick<GetAvailableStepsParams, 'isVipEnabled' | 'isEscortEnabled'>): Step[] =>
  isFlowStepAvailable(isVipEnabled, isEscortEnabled) ? [Step.Flow] : [];

const getEscortStep = ({
  values,
  isEscortEnabled,
}: Pick<GetAvailableStepsParams, 'values' | 'isEscortEnabled'>): Step[] =>
  isEscortEnabled && isEscortAvailable(values) ? [Step.Escort] : [];

const getHostStep = ({
  isHostsEnabled,
  availableFloors,
}: Pick<GetAvailableStepsParams, 'isHostsEnabled' | 'availableFloors'>): Step[] =>
  isHostsEnabled || !!availableFloors?.length ? [Step.Host] : [];

export const getCustomFieldsStep = ({
  isCustomFieldsStepAvailable,
}: Pick<GetAvailableStepsParams, 'isCustomFieldsStepAvailable'>): Step[] =>
  isCustomFieldsStepAvailable ? [Step.Custom_Fields] : [];

export const getAvailableSteps = ({
  values,
  isVipEnabled,
  isEscortEnabled,
  isHostsEnabled,
  availableFloors,
  isCustomFieldsStepAvailable,
}: GetAvailableStepsParams): Step[] => [
  ...getFlowStep({ isVipEnabled, isEscortEnabled }),
  Step.Visitor,
  ...getEscortStep({ isEscortEnabled, values }),
  ...getHostStep({ availableFloors, isHostsEnabled }),
  Step.Date,
  ...getCustomFieldsStep({ isCustomFieldsStepAvailable }),
];

interface GetNextStepParams extends GetAvailableStepsParams {
  currentStep: Step;
}

export const getNextStep = ({ currentStep, ...restParams }: GetNextStepParams): Step | null => {
  const availableSteps = getAvailableSteps(restParams);
  const currentStepIndex = availableSteps.indexOf(currentStep);

  if (currentStepIndex === availableSteps.length - 1) {
    return null;
  }

  return availableSteps[currentStepIndex + 1];
};
