import { BottomModalVariant } from '@hqo/react-components-library/dist/molecules/modals/bottomModal';
import { useIntl } from 'react-intl';
import { BottomModalStyled } from './visit-cancel-modal.styles';
import { useDateTimeFormatters } from 'shared/hooks';
import { useCancelButtons } from './visit-cancel-modal.hooks';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

interface Props {
  isVisible: boolean;
  onClose: () => void;
  onCancel: () => void;
  arrivalDate: Date;
}

const cancelIconProps: IconProp = ['fad', 'circle-xmark'];

export const VisitCancelModal = ({ isVisible, onClose: handleClose, onCancel: handleCancel, arrivalDate }: Props) => {
  const { formatMessage } = useIntl();
  const { formatDate, formatTime } = useDateTimeFormatters({
    dateFormat: { dateStyle: 'full' },
  });
  const arrivalDateFormatted = formatDate(arrivalDate);
  const arrivalTimeFormatted = formatTime(arrivalDate);
  const buttons = useCancelButtons(handleCancel, handleClose);

  return (
    <BottomModalStyled
      buttons={buttons}
      iconProps={cancelIconProps}
      onClose={handleClose}
      isVisible={isVisible}
      title={formatMessage({ id: 'labels.cancelVisit.title' })}
      content={formatMessage(
        { id: 'labels.cancelVisit.description' },
        { arrivalDate: arrivalDateFormatted, arrivalTime: arrivalTimeFormatted },
      )}
      variant={BottomModalVariant.CENTERED}
    />
  );
};
