import { BackLinkStyled, HeaderWrapper, ProgressBarStyled, StepIndicatorStyled } from './visit-form-header.styles';

import { useFormProgress } from './visit-form-header.hooks';
import { useFieldMetadata } from '../../../../../store/ui-metadata/hooks';

interface VisitFormHeaderProps {
  isEditMode?: boolean;
}
export const VisitFormHeader = ({ isEditMode }: VisitFormHeaderProps): JSX.Element => {
  const { percentage, stepNumber, stepsCount } = useFormProgress();
  const viewVisitsEnabled = useFieldMetadata('view_visits_enabled');
  const showBackButton = viewVisitsEnabled || stepNumber > 1;

  return (
    <HeaderWrapper>
      {!isEditMode && <ProgressBarStyled progress={percentage} />}
      {showBackButton && <BackLinkStyled isEditMode={isEditMode} />}
      {!isEditMode && <StepIndicatorStyled index={stepNumber} total={stepsCount} />}
    </HeaderWrapper>
  );
};
