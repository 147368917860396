import { useCallback, useState } from 'react';
import { useCancelVisit } from './visit-cancel-modal/visit-cancel-modal.hooks';

export const useVisitCancelModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { handleCancel } = useCancelVisit();

  const handleCancelClose = useCallback(() => {
    setIsVisible(false);
  }, [setIsVisible]);

  const handleCancelOpen = useCallback(() => {
    setIsVisible(true);
  }, [setIsVisible]);

  return { isVisible, handleCancelClose, handleCancelOpen, handleCancel };
};
