import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Building, FloorData } from 'store/building/types';

import { SelectItems } from 'components/visit-form/shared/types';
import { useCurrentUser } from 'hooks/use-current-user.hook';
import { useField, useFormikContext } from 'formik';
import { VisitFormValues } from 'components/visit-form/types';
import { VisitorRegistrationFlagProperties } from 'store/ui-metadata/types';
import { SelectType } from 'store/hosts/types';
import { useUIMetadataFieldRequirements } from 'components/visit-form/shared/form.hooks';

const useFormattedItems = (
  items: SelectItems[] | [],
  metadataKey?: keyof VisitorRegistrationFlagProperties,
): SelectItems[] | [] => {
  const { required } = useUIMetadataFieldRequirements(metadataKey);
  const { formatMessage } = useIntl();

  const altOption = {
    label: formatMessage({ id: 'fields.altSelectOption.label' }),
    value: SelectType.UNSELECTED,
  };
  return required ? items : [altOption, ...items];
};

function prepareFloorsForSelect(floors?: FloorData[]): SelectItems[] {
  return floors?.map((floor) => ({ label: floor.number, value: floor.number })) ?? [];
}

function getSelectedFloor(selectedFloorValue: string, floors?: FloorData[]): FloorData | undefined {
  if (floors && selectedFloorValue) {
    return floors.find((floor) => floor.number === selectedFloorValue);
  }

  return undefined;
}

function prepareSuitesForSelect(selectedFloorValue: string, floors?: FloorData[]): SelectItems[] | [] {
  const selectedFloor = getSelectedFloor(selectedFloorValue, floors);

  return selectedFloor?.suites.map((suite) => ({ label: suite, value: suite })) ?? [];
}

interface HostStepFormData {
  hostFirstName: string;
  hostLastName: string;
  floorItems: SelectItems[];
  suiteItems: SelectItems[];
  selectedFloorItem?: SelectItems;
  selectedSuiteItem?: SelectItems;
  handleChangeFloors: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

export const useFloorSelectChangeHandler = (): ((event: React.ChangeEvent<{ value: unknown }>) => void) => {
  const { setValues, values } = useFormikContext<VisitFormValues>();

  return useCallback(
    (event: React.ChangeEvent<{ value: unknown }>) => {
      setValues({
        ...values,
        host: {
          ...values.host,
          floor: event.target.value as string,
          suite: null,
        },
      });
    },
    [values, setValues],
  );
};

export function useHostStepFormData(building: Pick<Building, 'floors'> | null): HostStepFormData {
  const [currentUser] = useCurrentUser();
  const hostFirstName = `${currentUser?.first_name}`;
  const hostLastName = `${currentUser?.last_name}`;

  const floors = building?.floors;
  const floorItems = useFormattedItems(prepareFloorsForSelect(floors), 'floor');

  const [{ value: selectedFloorValue }] = useField('host.floor');

  const suiteItems = useFormattedItems(prepareSuitesForSelect(selectedFloorValue, floors), 'suite');

  const handleChangeFloors = useFloorSelectChangeHandler();

  return { hostFirstName, hostLastName, floorItems, suiteItems, handleChangeFloors };
}

export const useHostEmail = (): string => {
  const [currentUser] = useCurrentUser();
  return currentUser?.email ?? '';
};
