import styled from 'styled-components';
import { Switch } from '@hqo/react-components-library';

export const Wrapper = styled.div`
  margin: 12px 0 20px;
`;

export const SwitchWrapper = styled.div`
  display: flex;
`;

export const SwitchStyled = styled(Switch)`
  margin-right: 8px;
`;

export const RecurringContentWrapper = styled.div`
  margin-top: 8px;
`;
