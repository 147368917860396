import { Host } from './types';
import { RootState } from 'store/reducer';
import { createSelector } from 'reselect';
import { useSelector } from 'react-redux';

export const hostsState = (state: RootState): RootState['hosts'] => state.hosts;

export const hostsData = createSelector(hostsState, (state: RootState['hosts']): Host[] | null => state.data);

export const hostFieldValue = createSelector(hostsState, (state: RootState['hosts']): string => state.fieldValue);

export const useHostSearchResult = (): Host[] => {
  const hosts = useSelector(hostsData);
  const valueLowerCase: string = useSelector(hostFieldValue)?.toString().toLowerCase().trim() ?? '';

  return (
    hosts?.filter(
      (item) =>
        item.first_name.toLowerCase().includes(valueLowerCase) ||
        item.last_name.toLowerCase().includes(valueLowerCase) ||
        `${item.first_name} ${item.last_name}`.toLowerCase().includes(valueLowerCase),
    ) ?? []
  );
};
