import { VisitFormHeader } from 'components/visit-form/shared/step-wrapper/visit-form-header';
import { ContentWrapper } from 'components/visit-form/shared/step-wrapper/step-wrapper.styles';
import { StepProps } from 'components/visit-form/steps/step.props.interface';
import { useCallback } from 'react';
import { CustomFormContent } from 'components/visit-form/steps/custom-form-step/custom-form-content/custom-form-content';
import { DynamicFormDataDto } from '@hqo/dynamic-forms-sdk/types/form/form-data.types';
import { useFormikContext } from 'formik';
import { VisitFormValues } from 'components/visit-form/types';

export const CustomFormStep = ({ isEditMode }: StepProps): JSX.Element => {
  const { values, setValues, submitForm } = useFormikContext<VisitFormValues>();

  const onSubmit = useCallback(
    (formData: DynamicFormDataDto) => {
      setValues({ ...values, custom_fields: formData });
      submitForm();
    },
    [setValues, submitForm, values],
  );

  return (
    <>
      <VisitFormHeader isEditMode={isEditMode} />
      <ContentWrapper>
        <CustomFormContent onSubmit={onSubmit} />
      </ContentWrapper>
    </>
  );
};
