import { useCallback, useMemo } from 'react';
import moment, { Moment } from 'moment';

import { useField } from 'formik';
import { VisitDatePicker } from './visit-date-picker';

interface Props {
  fieldName: string;
  onChange?: (newValue: Moment) => void;
  minDate?: Moment;
}

export const DateField = ({ fieldName, onChange, minDate }: Props): JSX.Element => {
  const [{ value }, { touched, error }, { setValue, setTouched }] = useField<Date | null>(`date.${fieldName}`);
  const handleChange = useCallback(
    (newValue: Moment) => {
      setValue(newValue.toDate());
      setTouched(true, false);
      onChange?.(newValue);
    },
    [onChange, setTouched, setValue],
  );
  const momentValue = useMemo(() => value && moment(value), [value]);

  return (
    <VisitDatePicker
      momentValue={momentValue}
      handleChange={handleChange}
      touched={touched}
      error={error}
      fieldName={fieldName}
      minDate={minDate}
    />
  );
};
