import styled, { css } from 'styled-components';

import { mediaMaxWidth } from '@hqo/react-components-library/dist/device';

export const StyledForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${mediaMaxWidth(css`
    height: 100%;
  `)}
`;
